<template>
  <div style="font-size: 12px">
    <div class="PageContent">
      <div class="payState">
        <div style="display: inline">支付状态：</div>
        <div style="display: inline">{{d.status === 0 ? '未付款' : d.status === 1 ? '付款中': d.status === 2 ? '付款失败' : '付款成功' }}</div>
      </div>
      <div class="seg"></div>
      <!--标书费-->
      <div class="payList">
        <div style="font-size: 22px">
          <div style="display: inline">{{d.itemName}}</div>
        </div>
        <div class="listItem">
          <div style="display: inline">收款方：</div>
          <div style="display: inline">{{d.payeeName}}</div>
        </div>
        <div class="listItem">
          <div style="display: inline">收支类别：</div>
          <div style="display: inline">{{d.paymentType}}</div>
        </div>
        <div class="listItem">
          <div style="display: inline">金额：</div>
          <div style="display: inline">￥{{(d.money/100).toFixed(2)}}</div>
        </div>
        <div class="listItem">
          <div style="display: inline">备注：</div>
          <div style="display: inline">{{d.remark}}</div>
        </div>

        
        
      </div>
      <div class="seg"></div>
      <div class="payList">
        <div class="listItem">
          <div style="display: inline">支付方式：</div>
          <div style="display: inline">{{d.payMethod}}</div>
        </div>
        <div class="listItem">
          <div style="display: inline">支付单号：</div>
          <div style="display: inline">{{d.order_no}}</div>
        </div>

        <div class="listItem">
          <div style="display: inline">操作人员：</div>
          <div style="display: inline">{{d.userName}}</div>
        </div>
        <div class="listItem">
          <div style="display: inline">操作时间：</div>
          <div style="display: inline">{{d.payTime}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import {orderDetail} from '../../api/pay/orderForPay'
import request from '@/router/axios';
  export default {
    name: "orderForDetail",
    data(){
      return {
        d:{}
      }
    },
    methods :{
      getDetail(){
        const id = this.$route.query.id
        request({
          url: '/api/zt-pay/capitalPool/orderDetail',
          method: 'get',
          params: {id:id}
        }).then((res)=>{
            let rest = res.data.data
            // if(rest.payMethod === 'AliPay'){
            //   rest.payMethod = '支付宝'
            // }
            // if (rest.payMethod === 'MicroMessenger'){
            //   rest.payMethod = '微信'
            // }
            // if (rest.payMethod === 'artificial'){
            //   rest.payMethod = '人工'
            // }
            this.d = rest
        })
        
       
      }
    },
    mounted(){
      this.getDetail()
    }
  }
</script>

<style scoped>
  .head{
    width: 100%;
  }
  .avue-view{
    background-color: white;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .navigation{
    width: 97%;
    margin: 0 auto;
    font-size: 20px;
  }
  .PageContent{
    width: 97%;
    margin: 0 auto;
    /*background-color: #00a680;*/
  }
  .SEG{
    width: 100%;
    height: 1px;
    background-color: black;
    margin-top: 20px;
  }
  .payState{
    font-size: 25px;
    color: #70B603;
    padding-top: 30px;
  }
  .seg{
    width: 80%;
    height: 1px;
    border-top: 1px dashed #b3b3c1;
    margin-top: 30px;
  }
  .listItem{
    margin-top: 20px;
  }
  .payList{
    margin-top: 30px;
    font-size: 18px;
  }
</style>
