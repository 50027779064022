<template>
  <div>
    <basic-container>测试页</basic-container>
  </div>
</template>

<script>
  export default {
    name: "wel",
    data() {
      return {};
    },
    computed: {},
    created() {
    },
    methods: {}
  };
</script>

<style scoped="scoped" lang="scss">
</style>
