<template>
    <div class="botton" @click="encryption">去支付</div>
</template>
<style>
  .botton{
    width: 150px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border: 1px solid #2c3643;
  }
</style>
<script>
  import AEC from "@/api/aes"
  import {alipay} from "../../api/pay/alipay";

  export default {
    data(){
      return {
        key:'121321vfddfdhrsd',
        appId: '1',
        ts: '',//时间戳
        nonce: '',
        d: {
          money: '100',
          itemName: '项目标费',
          payeeName: '人员管理科',
          payer: '陈磊',
          phone: '18355565666',
          remark: 'xxx',
          payMethod:'AliPay',
          departName: '111',
          orderNo: ''
        }
      }
    },
    methods: {
      encryption (){
        this.ts = new Date().getTime()
        this.d.ts = this.ts
        console.log(this.ts)
        this.d.key = this.key
        this.nonce = this.createCode(6)
        this.d.nonce = this.nonce
        let a = AEC.encrypt(JSON.stringify(this.d),this.key)
        console.log(a)
        alipay(this.appId,this.nonce,this.ts,a).then((r) => {
          console.log(r)
        })
      },
      createCode(length) {
        let code = '';
        let codeLength = length;
        let random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9);
        for (let i = 0; i < codeLength; i++) {
          let index = Math.floor(Math.random() * 9);
          code += random[index];
        }
        return code
      }
    }
  }
</script>
