import request from '@/router/axios';

export const payshou = (payeeName,state) =>{
  return request({
    url: '/api/zt-pay/payee/savePayee',
    method: 'post',
    data:{
      payeeName: payeeName,
      state: state
    }
  })
}
