<template>
  <div>
    <div>项目：{{itemName}}</div>
    <avue-form  :option="option" v-model="form" @submit="submit" @reset-change="resetChange">
    </avue-form>
    <avue-form  :option="optionPay" v-model="formPay">
      <template slot="payLink">
        <div>
          <el-input style="width: 70%; margin-right: 20px" v-model="formPay.payLink" placeholder="支付链接"></el-input>
          <el-button type="" @click="copyLink">复制</el-button>
        </div>
      </template>
      <template slot="text">
        <div>
          <el-button type="primary" @click="downLoadImg">下载二维码</el-button>
          <div class="imageWrapper" ref="imageWrapper">
            <div class="imgHead">
              <span>{{itemName}}</span>
            </div>
            <div class="img">
              <img v-if="imgSrc !== '' && imgSrc !== undefined "
              border="0" :src="'data:image/png;base64,' + imgSrc"/>
            </div>
            <div class="imgFoot">
              <img :src="require('../../page/img/logo01.png')" alt="">
              <div class="line"></div>
              <div><span>中天钢铁集团统一收款平台</span></div>
            </div>
          </div>
          
        </div>
      </template>
    </avue-form>
  </div>
</template>

<script>
import { getList, update } from "@/api/pay/payShare";
import html2canvas from 'html2canvas';
export default {
   name:'payShare',
   props: ['itemId','itemName','isOpen'],
   data() {
      return {
        type: 0,
        imgSrc:'',
        dataURL: '',
        form: {
          // title:'我是头部标题',
          url: '',
          appId: '',
          secretKey: '',
        },
        formPay: {
          payLink: '',
          payImg: ''
        },
        obj: {
          title:'我是头部标题',
        },
        option: {
          detail:false,
          emptyBtn:false,
          group: [
            {
              label: '接入配置',
              prop: 'jbxx',
              icon: 'el-icon-edit-outline',
              column: [
                {
                  label: 'URL',
                  prop: 'url',
                  // detail:true,
                  row: true,
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: '请输入URL',
                  //     trigger: 'blur'
                  //   }
                  // ]
                },{
                  label: 'APP_ID',
                  prop: 'appId',
                  row: true,
                  disabled: true,
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: '请输入APP_ID',
                  //     trigger: 'blur'
                  //   }
                  // ]
                },
                {
                  label: '密钥',
                  prop: 'secretKey',
                  type:'textarea',
                  showWordLimit:true,
                  disabled: true,
                  row: true,
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: '请输入密钥',
                  //     trigger: 'blur'
                  //   }
                  // ]
                }
              ]
            }
          ]
        },
        optionPay: {
          menuBtn: false,
          
          group: [
            {
              label: '支付码和链接',
              prop: 'tksq',
              icon: 'el-icon-view',
              column: [
                {
                  label: '支付链接',
                  prop: 'payLink',
                  row: true,
                  formslot: true,
                },
                {
                  label: '支付二维码',
                  prop: 'text',
                  // type: 'load',
                  formslot:true
                  // span: 24,
                },
              ]
            }
          ]
        }
      }
    },
    methods:{
      getInfo(){
        let param = {itemId: this.itemId}
        getList(param).then(res => {
          let data = res.data.data
          this.form.url = data.url
          this.form.appId = data.app_id
          this.form.secretKey = data.secret_key
          this.imgSrc = data.qrCode
          this.formPay.payLink = data.pay_link            
        });
      },
      submit(form,done) {
          let param = {...form, itemId: this.itemId}
          update(param).then(res => {
            this.$message({
              type: "success",
              message: "操作成功!"
            });
            console.log(res)
            done()        
          });
      }, 
      resetChange(){
        this.form.url = ''
      },
      // 复制支付链接
      copyLink(){
        this.$Clipboard({
          text: this.formPay.payLink
        }).then(() => {
          this.$message.success('复制成功')
        }).catch(() => {
          this.$message.error('复制失败')
        });
      },
      toImage() {
        // alert(this.$refs.imageWrapper)
          html2canvas(this.$refs.imageWrapper,{
              backgroundColor: null
          }).then((canvas) => {
            // console.log(canvas)
              let dataURL = canvas.toDataURL("image/png");
              console.log(dataURL)
              this.dataURL = dataURL;
          });
      },
      // 下载二维码
      downLoadImg(){
        // this.toImage()
        html2canvas(this.$refs.imageWrapper,{
              backgroundColor: null
          }).then((canvas) => {
            // console.log(canvas)
              let dataURL = canvas.toDataURL("image/png");
              console.log(dataURL)  // 这里已经是base64的图片了


            // let base64 = this.imgSrc;
            // let raw = window.atob(base64)
            // let rawLength = raw.length;
            // let uInt8Array = new Uint8Array(rawLength);
            // for(let i = 0; i < rawLength; ++i) {
            //     uInt8Array[i] = raw.charCodeAt(i);
            // }
            // const blob =  new Blob([uInt8Array], {
            //     type: "image/png"
            // })
            
            let aLink = document.createElement('a');
            let evt = document.createEvent("HTMLEvents");
            evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
            aLink.download = "支付二维码";
            // aLink.href = URL.createObjectURL(blob);
            aLink.href = dataURL;
            aLink.click()
        });
        
      }
    },
    watch: {
      'itemId':{
        handler(val){
          // console.log("val===",val);
            // this.money = 0
          this.getInfo()
        },
        immediate: true
    }
    }
    
}
</script>

<style>
  .imageWrapper{
    background: #FFFFFF;
    max-width: 600px;
    text-align: center;
    padding-bottom: 10px;
    width: 600px;
    height: 800px;
    /* float: left; */
    margin-right: 30px;
    margin-top: 20px;
  }
  .imageWrapper img{
    width: 440px;
    height: 440px;
  }
  .imageWrapper .img{
    width: 100%;
    height: 440px;
    text-align: center;
  }
  .imgHead{
    background: #0056A7;
    font-size: 35px;
    color: #fff;
    height: 175px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 12px;
    font-size: 30px;
    /* width: 375px; */
  }
  .imgFoot{
    font-size: 22px; color: #000000;
    margin-top: -20px;
  }
  .imgFoot img{ width: 340px; height: 94px;}
  .imgFoot .line{ border-bottom: solid 1px #D0D0D0; 
  width: 360px; margin: 0 auto; margin-bottom: 5px;}
  .head{
    width: 100%;
  }
  .avue-view{
    background-color: white;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .avue-main{
    background-color: white;
  }
  .navigation{
    width: 97%;
    margin: 0 auto;
    font-size: 20px;
  }
  .PageContent{
    width: 92%;
    margin: 4% auto;
  }
  .PageTitle{
    font-size: 25px;
    color: #b3b3c1;
  }
  .ProjectName{
    font-size: 25px;
    margin-top: 20px;
  }
  .TitleItem{
    font-size: 20px;
    margin-top: 20px;
  }
  .seg{
    width: 80%;
    height: 1px;
    background-color: black;
    margin-top: 20px;
  }
  .LeftTitle{
    width: 7%;
    float: left;
    margin-top: 30px;
    font-size: 20px;
  }
  .RightContent{
    width: 55%;
    float: left;
    margin-top: 30px;
    font-size: 20px;
  }
  .left_title{
    height: 80px;
    line-height: 80px;
    color: #b3b3c1;
  }
  .right_content{
    height: 80px;
    line-height: 80px;
  }
  .right_input{
    width: 90%;
    padding-left: 5px;
    height: 40px;
    line-height: 40px;
    background-color: #f2f2f2;
  }
  .ConfirmBtn{
    background-color: white;
    width: 200px;
    height: 50px;
    letter-spacing: 5px;
  }
  .CopyBtn{
    margin-left: 10px;
    width: 150px;
    height: 45px;
    background-color: #f2f2f2;
  }
  .QR_Boder{
    width: 160px;
    height: 160px;
    border: 1px solid black;
    margin-top: 30px;
    float: left;
  }
  .DownloadBtn{
    margin-left: 10px;
    width: 150px;
    margin-top: 150px;
    height: 40px;
    background-color: #f2f2f2;
  }
</style>
