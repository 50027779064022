<template>
  <el-row>
    <el-col :span="5">
      <div class="box">
        <el-scrollbar>
          <basic-container>
            <!-- <avue-tree v-if="false" :option="treeOption" :data="treeData"
            default-expand-all
            current-node-key="互联网科"
            @node-click="nodeClick"/> -->

            <!--<el-input-->
              <!--placeholder="输入关键字进行过滤" size="small"-->
              <!--v-model="filterText">-->
            <!--</el-input>-->

            <el-tree
              class="filter-tree"
              node-key="id"
              :data="treeData1"
              :props="defaultProps"
              :filter-node-method="filterNode"
              @node-click="nodeClick"
              ref="treeTest">
            </el-tree>
          </basic-container>
        </el-scrollbar>
      </div>
    </el-col>
    <el-col :span="19">
      <basic-container>
        <avue-crud
          :option="option"
          :table-loading="loading"
          :data="data"
          ref="crud"
          :page.sync="page"
          v-model="form"
          @on-load="onLoad"
          @row-del="rowDel"
        >
          <template slot="menuLeft" slot-scope="{type,size,row}">
            <el-button type="info"
                       size="small"
                       plain
                       icon="el-icon-user"
                       @click="handleGrant">权限配置
            </el-button>
          </template>
        </avue-crud>
        <el-dialog title="权限配置"
                   append-to-body
                   :visible.sync="roleBox"
                   width="645px">

          <avue-form v-model="user" :option="option2" @submit="submit">
            <template slot="userType" slot-scope="{item}">
              <span>{{ item }}</span>
            </template>
          </avue-form>
        </el-dialog>
      </basic-container>
    </el-col>
  </el-row>
</template>

<script>
  import {GetTree,onLoad,selectPermission,addPermission,delPermission} from "@/api/power/power";
  export default {
    data(){
      return{
        filterText: '',
        classId:'',
        roleBox: false,
        treeData1: [],
        nowDate:{
          payeeId:'',
          departId:'',
          itemId:'',
          chooseType:0
        },
        page:{
          total:0,
          current:0,
          size:10
        },
        defaultProps: {
          children: 'children',
          label: 'title2',
          id:'id'
        },
        option:{
          height: 'auto',
          calcHeight: 80,
          tip: false,
          searchShow: false,
          searchMenuSpan: 6,
          border: true,
          index: true,
          selection: true,
          viewBtn: true,
          editBtn:false,
          addBtn:false,
          //dialogType: 'drawer',
          dialogClickModal: false,
          searchGutter:0,
          column:[
            {
              label:'用户编号',
              prop:'code',
              hide:true
            },
            {
              label: "工号",
              prop: "account",
            },
            {
              label: "用户姓名",
              prop: "userName",
            },
            {
              label: "手机号",
              prop: "phone",
            },
            {
              label:'部门',
              prop:'deptName',
            },
            {
              label:'性别',
              prop:'sex',
              type: "select",
              dicData:[
                {
                  label:'男',
                  value:1
                },
                {
                  label:'女',
                  value:2
                },
                {
                  label:'未知',
                  value:-1
                }
              ],
              hide:true
            }
          ]
        },
        loading:false,
        data:[],
        form:{},
        user:{
          userId:'',
          userId2:'',
          userName:'',
          payeeName:'',
          departId:'',
          departName:'',
          payeeId:'',
          itemName:'',
          itemId:'',
          state:'',
          id:''
        },
        option2:{
          width:500,
          column:[
            {
              label:'用户',
              prop: 'userId',
              type: 'select',
              span:20,
              props: {
                label: 'userName',
                value: 'id'
              },
              remote:true,
              dicUrl: `/api/blade-user/userPage?phone={{key}}`,
              typeformat(item) {
                //console.log('item='+`${item['realName']}//`)
                return `${item['deptName']}/${item['realName']}`
              },
              rules: [
                {
                  required: true,
                  message: '请输入用户',
                  trigger: 'blur'
                }
              ]
            }
          ]
        }
      }
    },
    created() {
      this.getTreeList()
      // if (this.$route.query.classId !== '' && this.$route.query.classId !== undefined){
      //   this.treeDeptId = this.$route.query.classId   // 搜索部门id
      //   this.treeClassId = this.$route.query.classId  // 设置tree选中状态的节点id
      // }
    },
    methods:{
      filterNode(value, data) {
        if (!value) return true;
        return data.title.indexOf(value) !== -1;
      },
      getTreeList(){
        GetTree().then((r)=>{
          let rest = []
          rest = this.Fn(r.data.data)
          // var arrStr = JSON.stringify(rest).replace(/title/g, "label")
          console.log(rest)
          this.treeData1 = rest
        })
      },
      Fn(data){
        data = data.map(item =>{
          return {
            id: item.id,
            payeeId:item.payeeId ? item.payeeId : '',
            departId:item.departId ? item.departId : '',
            itemId:item.itemId ? item.itemId : '',
            title2: item.title + '('+item.count+')',
            children:item.children ? this.Fn(item.children) : item.children
          }
        })
        return data
      },
      onLoad(){
        if(this.nowDate.payeeId !== '' || this.nowDate.departId !== '' || this.nowDate.itemId !== ''){
          let s = {
            current:0,
            size:10,
            payeeId:'',
            departId:'',
            itemId:'',
            chooseType:0
          }
          if(this.nowDate.chooseType === 0){
            s.payeeId = this.nowDate.payeeId
          }else if (this.nowDate.chooseType === 1){
            s.departId = this.nowDate.departId
          }else if(this.nowDate.chooseType === 2){
            s.itemId = this.nowDate.itemId
          }
          selectPermission(this.page.current,this.page.size,s.payeeId,s.departId,s.itemId,this.nowDate.chooseType).then((r)=>{
            this.data = r.data.data.records
            this.page.total = r.data.data.total
          })
        }else{
          this.$message({
            type: "success",
            message: "请先选择角色方!"
          });
        }
      },
      nodeClick(data){
        console.log(data)
        let s = {
          current:0,
          size:10,
          payeeId:'',
          departId:'',
          itemId:'',
          chooseType:0
        }
        if(data.payeeId === ''){//收款方
          s.payeeId = data.id
          this.nowDate.departId = ''
          this.nowDate.payeeId = data.id
          this.nowDate.itemId = ''
          this.nowDate.chooseType = s.chooseType
        }else if(data.departId === ''){//收款部门
          //s.payeeId = data.payeeId
          s.chooseType = 1
          s.departId = data.id
          this.nowDate.departId = data.id
          this.nowDate.payeeId = data.payeeId
          this.nowDate.itemId = ''
          this.nowDate.chooseType = 1
        }else{
          //s.payeeId = data.payeeId
          //s.departId = data.departId
          s.itemId = data.id
          s.chooseType = 2
          this.nowDate.departId = data.departId
          this.nowDate.payeeId = data.payeeId
          this.nowDate.itemId = data.id
          this.nowDate.chooseType = 2
        }
        selectPermission(s.current,s.size,s.payeeId,s.departId,s.itemId,s.chooseType).then((r)=>{
          console.log(r)
          this.data = r.data.data.records
          this.page.total = r.data.data.total
        })
      },
      handleGrant(){
        if(this.nowDate.payeeId === ''&& this.nowDate.departId === '' && this.nowDate.itemId === ''){
          this.$message({
            type: "error",
            message: "请先选择收款方!"
          });
          return
        }
        this.roleBox = true;
      },
      submit(from,done){
        let row = {}
        row.chooseType = this.nowDate.chooseType
        row.payeeId = this.nowDate.payeeId
        row.departId = this.nowDate.departId
        row.itemId = this.nowDate.itemId
        row.userId = from.userId
        addPermission(row.userId,row.payeeId,row.departId,row.itemId,row.chooseType).then((res)=>{
          console.log(res)
          if(res.data.code === 200){
            this.$message({
              type: "success",
              message: "操作成功!"
            });
            this.onLoad()
            this.roleBox = false
          }else{
            this.$message({
              type: "error",
              message: res.data.msg
            });
          }
          done()
        })
      },
      rowDel(row){
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return delPermission(row.userId,this.nowDate.payeeId,this.nowDate.departId,this.nowDate.itemId,this.nowDate.chooseType);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
          });
      }
    }
  }
</script>

<style>
  .box {
    height: 800px;
  }

  .el-scrollbar {
    height: 100%;
  }

  .box .el-scrollbar__wrap {
    overflow: scroll;
  }
  .hid{
    display: none;
  }
</style>
