import request from '@/router/axios';

// 获取表格数据 列表
export const getList = (current, size, params) => {
  return request({
    url: '/api/zt-pay/outgoingCollection/list',
    method: 'post',
    params: {
      ...params,
      current,
      size,
    }
  })
}
