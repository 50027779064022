<template>
  <basic-container>
    <avue-crud :option="option"
             :table-loading="loading"
             :page.sync="page"
             :data="data"
             ref="crud"
             :v-model="form"
             @refresh-change="refreshChange"
             @search-change="searchChange"
             @date-change="dateChange"
             @search-reset="searchReset"
               @on-load="onload"
            >
      <!--导入-->
      <template slot="header">
        <avue-select placeholder="导出类型" v-model="search.exportType" :dic="dicExprot" style="width: 200px !important;padding-right: 30px;"></avue-select>
        <el-button type="primary" size="small" class="exportBtn" @click="exportData">导出</el-button>
      </template>
      <!--支付单号-->
      <template slot="orderNoSearch">
        <avue-input placeholder="请输入支付单号" v-model="search.orderNo"></avue-input>
      </template>
      <!--手机-->
      <template slot="phoneSearch">
        <avue-input placeholder="请输入手机号" v-model="search.phone"></avue-input>
      </template>
      <!--收款方-->
      <template slot="payeeIdSearch">
        <avue-select placeholder="请选择收款方" v-model="search.payeeId" :dic="dicPayeeName" @change="payeeChange"></avue-select>
      </template>
      <!--收款部门-->
      <template slot="departNameSearch">
        <avue-select placeholder="请选择收款部门" v-model="search.departId" :dic="dicDepartName" @change="departChange"></avue-select>
      </template>
      <!--支付项目-->
      <template slot="itemNameSearch">
        <avue-select placeholder="请选择支付项目" v-model="search.itemId" :dic="dicItemName"></avue-select>
      </template>
      <!--支付状态-->
      <template slot="statusSearch">
        <avue-select placeholder="请选择支付状态" v-model="search.status" :dic="dicStatus"></avue-select>
      </template>
      <!--支付方式-->
      <template slot="payMethodSearch">
        <avue-select placeholder="请选择支付方式" v-model="search.payMethod" :dic="dicMethod"></avue-select>
      </template>

      <!--按钮-->
      <!--列表 里新增按钮-->
      <template slot-scope="{type,size,row}" slot="menu">
        <el-button icon="el-icon-view" :size="size" :type="type" @click="GoToDetail(row)">查看</el-button>
      </template>
      <!--弹框 里新增按钮-->
      <!--<template slot-scope="{row,index,type}" slot="menuForm">-->
        <!--<el-button type="primary">打印</el-button>-->
      <!--</template>-->
    </avue-crud>
  </basic-container>
</template>
<script>
  import {mapGetters} from "vuex";
  import request from '@/router/axios';

  import {orderList,orderExport} from "@/api/pay/orderForPay";
  const defaultQuery = {
    name: '',
    phone: '',
    orderNo: '',
    status: '',
    payeeName: '',
    itemName: '',
    payeeId: '', // 收款方
    startTime: '',
    endTime: '',
    departName:'' //  收款部门
  }
  export default {
    name: "orderForPay",
    data(){
      return{
        query:{},
        selectionList: [],
        form:{
        },
        search:{
          orderNo:'',
          itemId: '',
          phone:'',
          payeeId:'', // 收款方
          departId: '', //收款部门
          status:'',
          payMethod:'',
          exportType:''//导出类型
        },
        dicPayeeName:[],
        dicDepartName:[],
        dicItemName:[],
        dicExprot:[
          {
            label:'详情导出',
            value:'detail'
          },
          {
            label:'合计导出-日',
            value:'day'
          },
          {
            label:'合计导出-月',
            value:'month'
          },
          {
            label:'合计导出-年',
            value:'year'
          },
        ],
        dicStatus:[
          {
            label: "付款中",
            value: 1
          },
          {
            label: "未付款",
            value: 0
          },
          {
            label: "支付失败",
            value: 2
          },
          {
            label: "支付成功",
            value: 3
          }
        ],
        // 支付方式
        dicMethod:[ {
          label: "支付宝",
          value: 'AliPay'
        },{
            label: "微信",
            value: 'MicroMessenger'
          }],
        page: {
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        startTime:'',
        endTime:'',
        data: [],
        loading:true,
        option:{
          tip: false, //行可选情况下的选中提示
          menuWidth:120,// 表格 操作列宽度
          simplePage: true,
          searchShow: true,
          searchMenuSpan: 6, //搜索菜单栏的栅格宽度
          dateBtn:true,
          dialogType: 'drawer', // 弹窗——打开方式
          // dialogDirection: 'ltr', // ltr:从左往右 rtl:从右往左 ttb: 从上往下 btt: 从下往上
          tree: true,
          border: true,
          index: true, // 开启索引
          selection: true, //开启勾选功能

          addBtn:false,// 新增 弹框
          delBtn:false,// 删除 弹框
          editBtn:false, // 编辑 弹框
          menuType:'text',
          viewBtn: false, // 查看 彈框——查看
          viewTitle:'订单详情', // 查看 彈框——标题
          // dialogWidth: 500,
          // dialogClickModal: true,
          // dialogTitle:'订单详情',
          // dialogFilter:'订单详情',

          column: [ // 表格 表头配置
            {
              label: "支付单号",
              prop: "orderNo",
              search: true,
              searchslot:true,
              editDisabled: false,
              addDisplay:false,
              rules: [
                {
                  required: true,
                  message: "请输入支付单号",
                  trigger: "blur"
                }
              ]
            },
            {
              label: "支付时间",
              prop: "payTime",
              editDisabled: false,
              addDisplay:false
            },
            {
              label: "金额",
              prop: "money",
            },
            {
              label: "支付用户",
              prop: "payer",
            },
            {
              label: '手机号',
              prop:'phone',
              search: true,
              searchslot:true,
            },
            {
              label: "收款方",
              prop: "payeeId",
              type: "select",
              search: true,
              searchslot:true,
              cascaderItem: ['departName'],
              dicUrl: "/api/zt-pay/payee/payeeList",
              editDisabled: false,
              editable:false,
              props: {
                label: "payeeName",
                value: "payeeId"
              },
              rules: [
                {
                  required: true,
                  message: '请选择收款方',
                  trigger: 'blur'
                }
              ]
            },
            {
              label: "收款部门",
              prop: "departName",
              type: 'select',
              search: true,
              searchslot:true,
              cascaderItem: ['itemId'],
              cascaderIndex:0,
              dicUrl:"/api/zt-pay/department/list?departName={{payeeId}}",
              editDisabled: false,
              editable:false,
              props: {
                label: 'departName',
                value: 'departId'
              },
              rules: [
                {
                  required: true,
                  message: '请选择收款部门',
                  trigger: 'blur'
                }
              ]
            },
            {
              label: "支付项目",
              prop: "itemName",
              search: true,
              searchslot:true,
              rules: [
                {
                  required: true,
                  message: "请输入支付项目",
                  trigger: "blur"
                }
              ]
            },
            {
              label: "支付状态",
              prop: "status",
              searchslot:true,
              type:'radio',
              search: true,
              dicData: [
                {
                  label: "付款中",
                  value: 1
                },
                {
                  label: "未付款",
                  value: 0
                },
                {
                  label: "支付失败",
                  value: 2
                },
                {
                  label: "支付成功",
                  value:  3
                }
              ],
            },
            {
              label: "支付方式",
              prop: "payMethod",
              searchslot:true,
              search: true,
              type:'radio',
              dicData: [
                {
                  label: "支付宝",
                  value: 'AliPay'
                },
                {
                  label: "微信",
                  value: 'MicroMessenger'
                },
                {
                  label: "人工",
                  value: 'artificial'
                }
              ],
            },
            {
              label: "备注",
              prop: "remark",
            },
          ]
        }
      }
    },
    watch:{
      'search.payeeId':{
        handler(val){
          this.search.departId = ''
          this.search.itemId = ''
          this.dicDepartName = []
        },
        immediate: true
      },
      'search.departId':{
        handler(val){
          this.search.itemId = ''
          this.dicItemName = []
        },
        immediate: true
      }
    },
    methods: {
      dateChange(data){
        console.log(data)
        // alert(data)
        if (data !== null && data !== '') {
          this.startTime = data[0].substring(0, 10)
          this.endTime = data[1].substring(0, 10)
        }else{
          this.startTime = ''
          this.endTime = ''
        }
        this.page.currentPage = 1
        this.onload(this.page)
      },
      selectionClear() {
        alert(1)
        this.selectionList = [];
        this.$refs.crud.toggleSelection();
      },
      onload(page, params = {}){
        //this.data = []
        console.log(this.search)
        if(this.startTime != ''){
          params.startTime = this.startTime
          params.endTime = this.endTime
        }
        //```
        let param = {...params,...this.search,itemName: this.search.itemId}
        orderList(page.currentPage, page.pageSize, Object.assign(param, this.query)).then((res)=>{
          const data = res.data.data;
          data.records.map((item)=>{
            item.money = parseFloat(Number(item.money) / 100).toFixed(2)
          })
          this.page.total = data.total;
          this.data = data.records;
          console.log(data.records)
          console.log(this.data)
          this.loading = false;
          // this.selectionClear()
          console.log(res)
        })
      },
      searchChange(params,done) {
        //let param = {...params,...this.search, itemName: this.search.itemName}
        this.page.currentPage = 1;
        this.onload(this.page, params);
        done();
      },
      refreshChange() {
        console.log(1)
        this.onload(this.page, this.query);
      },
      // ```
      searchReset(){
        this.search.orderNo = ''
        this.search.payeeId = ''
        this.search.phone = ''
        this.search.departId = ''
        this.search.status = ''
        this.search.payMethod = ''
        this.search.itemId = ''
        this.getItemData()
      },
      GoToDetail(row){
        console.log(row.id)
        this.$router.push({ path: '/order/orderForDetail?id='+ row.id})
      },
    //  获取收款方数据
      getPayeeData(){
        request({
          url: '/api/zt-pay/payee/payeeList',
          method: 'get',
        }).then((res)=>{
          let rest = res.data.data
          let arr = []
          rest.forEach(item => {
            arr.push({label: item.payeeName, value:item.payeeId})
          });
          this.dicPayeeName = arr
        })
      },
    //  获取收款部门数据
      getDepartData(payeeId){
        request({
          url: '/api/zt-pay/department/list',
          method: 'get',
          params: {payeeId:payeeId}
        }).then((res)=>{
          let rest = res.data.data
          let arr = []
          rest.forEach(item => {
            // console.log(item)
            arr.push({label: item.departName, value:item.departId})
          });
          this.dicDepartName = arr
        })
      },
      //  收款方下拉
      payeeChange(payeeId){
        // console.log(this.search.payeeId)
        //  收款方改变，置空收款部门、支付项目
        this.search.departId = ''
        this.search.itemId = ''
        // 支付项目存在——申请收款部门
        if(payeeId !== '' && payeeId !== undefined){
          this.getDepartData(payeeId)
          //  获取 选中收款方 下对应 支付项目列表
          let param = {payeeId:this.search.payeeId}
          this.getItemData(param)
        }
      },
    //  收款部门下拉
      departChange(departId){
        // 收款部门改变——置空支付项目
        this.search.itemId = ''
        // 获取支付项目数据
        if(departId !== '' && departId !== undefined){
          // 获取 选中收款方、收款部门 下对应 支付项目列表
          let param = {payeeId:this.search.payeeId,departId:departId}
          this.getItemData(param)
        } else {
          // 收款部门为空，对应收款方下的支付项目列表
          let param = {payeeId:this.search.payeeId}
          this.getItemData(param)
        }
      },
      // 获取支付项目数据
      getItemData(params){
        request({
          url: '/api/zt-pay/payItems/itemList',
          method: 'get',
          params: params
        }).then((res)=>{
          let rest = res.data.data
          let arr = []
          rest.forEach(item => {
            arr.push({label: item.itemName, value:item.itemId})
          });
          this.dicItemName = arr
        })
      },
      // 导出
      exportData(){
        // console.log(this.search.departId)
        this.$confirm("此操作将导出excel文件, 是否继续?","提示",{
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(()=> {
          orderExport(this.search.payMethod,this.search.orderNo,this.search.itemId,this.search.phone,this.search.payeeId,this.search.departId,this.search.status,this.startTime,this.endTime,this.search.exportType).then(res => {
            if(res.status !== 200) {
              return this.$message.error('导出失败，请重试！')
            } else {
              console.log(res.data)
              const blob = new Blob([res.data],{
                type: "application/vnd.ms-excel"
              })
              let link = document.createElement('a')
              link.href = URL.createObjectURL(blob) // 创建url对象
              link.setAttribute('download','支付项目表.xlsx')
              link.click()
              link = null
              return this.$message.success('导出成功！')
            }
          })
        })
      }
    },
    created(){
      console.log(this.$route.params)
      if (this.$route.query.itemId !== undefined && this.$route.query.itemId !== '') {
        this.search.itemId = this.$route.query.itemId
      }else if(this.$route.params.status !== undefined && this.$route.params.status !== ''){
        this.search.payMethod = this.$route.params.payMethod
        this.search.departId = this.$route.params.departId
        this.search.payeeId = this.$route.params.payeeId
        this.search.status = 3
        //this.search.startTime = this.$route.query.startTime
        this.startTime = this.$route.params.startTime
       // this.search.endTime = this.$route.query.endTime
        this.endTime = this.$route.params.endTime
        this.search.itemId = this.$route.params.id
      }
    },
    mounted(){
      this.getItemData()
      // if (this.$route.query.status !== undefined && this.$route.query.status !== '') {
      //   this.search.payMethod = this.$route.query.payMethod
      //   this.search.departId = this.$route.query.departId
      //   this.search.payeeId = this.$route.query.payeeId
      //   this.search.status = this.$route.query.status
      //   this.search.startTime = this.$route.query.startTime
      //   this.search.endTime = this.$route.query.endTime
      //   setTimeout(()=>{
      //     //this.search.itemId = this.$route.query.itemName
      //     this.onload(this.page);
      //   },300)
      // }
      this.getPayeeData()
    }
  }
</script>

<style scoped>
  .exportBtn{
    margin-bottom: 30px;
  }
</style>
