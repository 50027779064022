<template>
    <div>
        <!-- 支付资金池 -->
        <basic-container>
            <div class="balance">
                <span>{{$route.query.payeeName}}  资金池余额：¥{{balance}}</span>
                <!-- <el-button type="primary" size="small">查看资金流向</el-button> -->
            </div>
            <div>
                <avue-crud ref="crud" :data="data" :option="option" :page.sync="page" 
                @on-load="onLoad" @search-change="searchChange" @search-reset="searchReset">
                    <template slot-scope="{type,size,row}" slot="menu">
                        <el-button  icon="el-icon-view" :size="size" :type="type" @click="gotoDetail(row)">查看</el-button>
                        <!-- <el-button icon="el-icon-check" :size="size" :type="type">人工转账</el-button> -->
                    </template>
                    <template slot="paydateSearch">
                      <div>
                        <avue-date v-model="search.startTime" format="yyyy年MM月dd日" value-format="yyyy-MM-dd" placeholder="请选择开始日期"></avue-date>
                        <avue-date v-model="search.endTime" format="yyyy年MM月dd日" value-format="yyyy-MM-dd" placeholder="请选择结束日期"></avue-date>
                      </div>
                    </template>
                </avue-crud>
            </div>
            <div :class=" data.length > 0 ? 'moneyTotal' : 'noPage' ">
              <span>转入金额：{{ inMoney }}</span>
              <span>转出金额：{{ outMoney }}</span>
            </div>
        </basic-container>
    </div>
</template>
<script>
import request from '@/router/axios';
export default {
    data() {
      return {
        loading: true,
        page: {
          pageSize: 10,
          pagerCount:5
        },
        data: [],
        balance: '',
        search:{
          startTime:'',
          endTime:''
        },
        inMoney: '',
        outMoney: '',
        option: {
          align: 'center',
          menuAlign: 'center',
          addBtn: false,
          editBtn: false,
          delBtn: false,
          searchShow: true,
          searchSpan:6,
          column: [
            {
              label: '操作时间',
              prop: 'payTime'
            },
            {
              label: '收支类别',
              search: true,
              prop: 'paymentType',
              type: 'select',
              searchSpan: 5,
              dicData: [
                {
                  label: "收入",
                  value: 1
                },
                {
                  label: "支出",
                  value: 2
                }
              ],
            },
            // {
            //   label: '收款方',
            //   // search: true,
            //   prop: 'payeeName',
            //   type: 'select',
            //   // dicUrl: '/api/zt-pay/payee/payeeList',
            //   props: {
            //     label: 'payeeName',
            //     value: 'payeeId',
            //   },
            // },
            {
              label: '收支方式',
              search: true,
              prop: 'payMethod',
              type: 'select',
              searchSpan: 5,
              dicData: [
                {
                  label: "支付宝",
                  value: 'AliPay'
                },
                {
                  label: "微信",
                  value: 'MicroMessenger'
                },
                {
                  label: "人工",
                  value: 'artificial'
                }
              ],
            },
            {
              label: '操作人员',
              prop: 'userName'
            },
            {
              label: '支付金额',
              prop: 'money'
            },
            {
              label: '支付时间',
              search: true,
              searchslot:true,
              hide: true,
              prop:'paydate',
              searchSpan: 12
            },
            {
              label: '备注',
              prop: 'remark'
            }
          ]
        }
      }
    },
    mounted(){
        this.getPoolTotal()
        this.getMoneyTotal(this.search)
    },
    methods: {
      onLoad(page, params) {
        this.loading = true;
        let payeeId = ''
        if (this.$route.query.payeeId !== undefined){
          payeeId = this.$route.query.payeeId
        }
        let query = { current:page.currentPage, size:page.pageSize, payeeId: payeeId, ...params }
        request({
          url: '/api/zt-pay/capitalPool/payeeList',
          method: 'get',
          params: query
        }).then((res)=>{
          const data = res.data.data;
          this.page.total = data.total;
          data.records.map((item)=>{
            item.money = parseFloat(Number(item.money) / 100).toFixed(2)
          })
          this.data = JSON.parse(JSON.stringify(data.records));
          this.loading = false;
        })
      },
      getPoolTotal(){
        // 
        request({
          url: '/api/zt-pay/capitalPool/balance',
          method: 'get'          
        }).then((res)=>{
            let rest = res.data.data
            this.balance =  parseFloat(Number(rest.balance) / 100).toFixed(2)
        })
      },
      // 金额统计
      getMoneyTotal(params){
        let payeeId = ''
        if (this.$route.query.payeeId !== undefined){
          payeeId = this.$route.query.payeeId
        }
        let query = { payeeId: payeeId, ...params }
        request({
          url: '/api/zt-pay/capitalPool/payeeStatistics',
          method: 'get',
          params: query
        }).then((res)=>{
            let rest = res.data.data
            this.inMoney = rest.inMoney
            this.outMoney = rest.outMoney
            // this.balance =  parseFloat(Number(rest.balance) / 100).toFixed(2)
        })
      },
      // 到详情页
      gotoDetail(row){
        console.log(row)
        if(row.payMethod === '支付宝' || row.payMethod === '微信'){
          this.$router.push({path: '/order/orderForDetail', query: {id: row.id}})
        }else{
          this.$router.push({path: '/capitalPool/transferDetail', query: {id: row.id}})
        }
      },
      // 表单条件查询
     searchChange(params,done) {
        console.log(params)
        let param = {...params,...this.search}
        // console.log(this.search)
        // console.log(param)
        this.page.currentPage = 1;
        this.onLoad(this.page, param);
        // let Tparam = JSON.parse(JSON.stringify(param));
        // if (Tparam.paymentType !== undefined) {
        //   delete Tparam.paymentType
        // }
        this.getMoneyTotal(param)
        done();
      },
      searchReset(){
        this.search = {
          startTime:'',
          endTime:''
        }
      },
    }
}
</script>
<style scoped>
    .balance span{ margin-right: 15px;}
    /* .el-button{ margin-left: 15px;} */
    .balance{ margin-bottom: 20px;}
    /deep/.avue-date{ width: 40%; float: left; margin-right: 10px;}
    .noPage{
      font-size: 14px;
      padding: 0px 10px; 
      float: left;
      position: relative;
      top: -25px;
    }
    .moneyTotal{
      padding: 0px 10px; 
      float: left;
      position: relative;
      top: -50px;
      font-size: 14px;
    }
    .noPage > span, .moneyTotal > span{
      margin-right: 30px;
    }
</style>
