<template>
  <div>
    <!--平台数据-->
    <div class="platform">
      <div class="platform_title">平台数据：</div>
      <!-- <div class="seg"></div> -->
      <div class="platform_content" v-if="false">
        <avue-data-display :option="optionTotal"></avue-data-display>
      </div>
      <div class="platform_content" >
        <div class="Items">
          <div class="Items_Cash">{{moneyTotal.monthOrders}}</div>
          <div>本月在线支付笔数</div>
        </div>
        <div class="Items">
          <div class="Items_Cash">￥{{moneyTotal.monthMoney}}</div>
          <div>本月在线支付金额</div>
        </div>
        <div class="Items">
          <div class="Items_Cash">{{moneyTotal.orders}}</div>
          <div>总计在线支付笔数</div>
        </div>
        <div class="Items noBorder">
          <div class="Items_Cash">￥{{moneyTotal.money}}</div>
          <div>总计在线支付金额</div>
        </div>
        <!-- <div style="clear: both"></div> -->
      </div>
    </div>
    <!-- <div style="background-color: white;width: 100%;height: 30px"></div> -->
    <!--项目数据-->
    <div class="Project">
      <!-- <div class="Project_title">项目数据：</div> -->
      <!-- <div class="seg"></div> -->
      <!--表格内容-->
      <basic-container>
        <avue-crud :option="option"
               :table-loading="loading"
               :data="data"
               :page.sync="page"
               @on-load="onLoad"
               @refresh-change="refreshChange"
               ref="crud">
          <template slot="menuLeft">
            <span class="tableTitle">支付项目看板：</span>
          </template>
        </avue-crud>
      </basic-container>
    </div>
  </div>
</template>

<script>
import { getList, getMoneyTotal } from "@/api/pay/paykanban";
export default {
  data() {
    return {
      loading: true,
      data: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      moneyTotal:{
        money: '',   // 本月在线支付笔数
        monthMoney: '',   // 本月在线支付金额
        monthOrders: '',  // 总计在线支付笔数
        orders: ''  // 总计在线支付金额
      },
      option:{
        page:true,
        align:'center',
        menuAlign:'center',
        // title:'表格的标题',
        // searchShow: true,
        // searchSpan: 5,
        // searchMenuSpan: 4,
        // menuWidth: 300,
        delBtn: false,
        addBtn: false,
        // viewBtnText: '账单查看',  
        editBtn: false,
        menu:false,
        // addBtnText: '添加数据',
        // editBtnText: '123',
        column:[
          {
            label:'项目名称',
            prop:'itemName',
            // span: 10,
          },
          {
            label:'当月在线支付笔数',
            prop:'orders',
          },
          {
            label:'当月在线支付金额',
            prop:'money',
            format: '￥'
          }
        ]
      },
      optionTotal: {
        data: [
        {
            count: 100,
            title: '本月在线支付笔数',
          },
          {
            count: '3000',
            title: '本月在线支付金额',
          },
          {
            count: '20,000',
            title: '总计在线支付笔数',
          },
          {
            count: '40,000',
            title: '总计在线支付金额',
          }
        ]
      }
    }
  },
  mounted(){
    this.getTotal();
  },
  methods: {
    // 获取表格列表数据 
    onLoad(page, params) {
      this.loading = true;
      getList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        data.records.map((item)=>{
          item.money = parseFloat(Number(item.money) / 100).toFixed(2)
        })
        this.data = JSON.parse(JSON.stringify(data.records));
        // console.log(this.data);
        this.loading = false;
      });
    },
    getTotal(){
      getMoneyTotal().then((res)=>{
        const data = res.data.data
        data.money = parseFloat(Number(data.money) / 100).toFixed(2)
        data.monthMoney = parseFloat(Number(data.monthMoney) / 100).toFixed(2)
        this.moneyTotal = JSON.parse(JSON.stringify(data))
      })
    },
    refreshChange(){
      this.onLoad(this.page)
      this.getTotal();
      // this.$message({
      //   message: `当前的返回的分页对象${JSON.stringify(this.page)} 搜索表单对象${JSON.stringify(this.search)}`,
      //   type: 'success',
      // });
    },
  },
}
</script>

<style>
  .head{
    width: 100%;
  }
  .avue-crud{
    width: 100%;
  }
  
  .avue-view{
    /* height: calc(100% - 184px) !important; */
    /* width: 100%; */
    /* margin: 10px; */
    /* padding: 10px 15px; */
    background: rgb(240, 242, 245) !important;
    /* padding-bottom: 20px; */
  }
  .platform{
    /* background-color: #f2f2f2; */
    /* width: 97%; */
    margin: 0 auto;
    height: 200px;
    border: solid 1px #e2dddd;
    margin-bottom: 15px;
    /* padding: 30px 30px; */
  }
  
  .platform_title{
    /* padding-bottom: 30px; */
    font-size: 18px;
    /* line-height: 28px; */
    vertical-align: middle;
    height: 50px;
    line-height: 50px;
    background: #fff;
    /* font-weight: bold; */
    padding: 0px 15px;
    box-sizing: border-box;
  }
  .seg{
    height: 1px;
    background-color: #b5b5b5;
  }
  .platform_content{
    /* margin-top: 30px; */
    height: 150px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    font-size: 18px;
  }
  .Items{
    width: 25%;
    border-right: solid 1px #e2dddd;
  }
  .noBorder {
    border-right: 0;
  }
  .Items_Cash{
    /* font-weight: bold; */
    font-size: 40px;
    margin-bottom: 10px;
    /*text-align: center;*/
  }
  .Project_title{
    padding-bottom: 30px;
    font-size: 25px;
    font-weight: bold;
  }
  .tableTitle{
    font-size: 18px;
  }
  .basic-container{
    padding: 0;
  }

  .avue-data-display .item{

  }
</style>
