<template>
  <div style="padding:15px;">
    <div style="padding-bottom: 15px;">新增收款操作用户</div>
    <div style="padding-bottom: 15px;">第一步：指定收款项目</div>
    <avue-form :option="option2" v-model="user" @submit="submit"></avue-form>
    <div style="padding: 15px 0px;">第二步：选择用户</div>
    <!--<avue-form v-model="user2" :option="option" @change="changeUserId($event)">-->
      <!--<template slot="userType" slot-scope="{item}">-->
        <!--<span>{{ item }}</span>-->
      <!--</template>-->
    <!--</avue-form>-->
    <div style="background: #ffffff;padding:30px;text-align: center;">
      <el-select
        class="select_"
        v-model="user2.userIds"
        multiple
        filterable
        remote
        reserve-keyword
        placeholder="输入用户名或手机号"
        :remote-method="remoteMethod"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.deptName+'/'+item.realName"
          :value="item.id"
        />
      </el-select>
      <el-button type="primary" @click="submit" style="margin-top: 30px;">提交</el-button>
    </div>

  </div>
</template>

<script>
  import { getList, update, editdate, getPersonal} from "@/api/pay/AddUser";
  export default {
    name:'payShare',
    data() {
      return {
        type: 0,
        Uid:'',
        dicUser:[],
        user2:{
          userIds:''
        },
        dicPayeeName:[],
        user:{
          userId:'',
          userIds:'',
          userId2:'',
          userName:'',
          payeeName:'',
          departId:'',
          departName:'',
          payeeId:'',
          itemName:'',
          itemId:'',
          state:'',
          id:''
        },
        options:[],
        option:{
          submitBtn:true,
          emptyBtn:true,
          width:500,
          column:[
            {
              label:'用户',
              prop: 'userIds',
              type: 'select',
              span:20,
              props: {
                label: 'userName',
                value: 'id'
              },
              remote:true,
              dicUrl: `/api/blade-user/userPage?phone={{key}}`,
              typeformat(item) {
                //console.log('item='+`${item['realName']}//`)
                return `${item['deptName']}/${item['realName']}`
              },
                rules: [
                {
                  required: true,
                  message: '请输入用户',
                  trigger: 'blur'
                }
              ]
            }
          ]
        },
        option2:{
          submitBtn:false,
          emptyBtn:false,
          column:[{
            label:'收款方',
            type: 'select',
            prop: 'payeeId',
            cascaderItem: ['departId'],
            dicUrl: '/api/zt-pay/payee/payeeList',
            editDisabled: false,
            props: {
              label: 'payeeName',
              value: 'payeeId',
            },
            rules: [
              {
                required: true,
                message: '请选择收款方',
                trigger: 'blur'
              }
            ]
          },{
            label: '收款部门',
            prop: 'departId',
            type: 'select',
            cascaderItem:['itemId'],
            dicUrl: '/api/zt-pay/department/list?payeeId={{payeeId}}',
            props: {
              label: 'departName',
              value: 'departId'
            },
            rules: [
              {
                required: true,
                message: '请选择收款部门',
                trigger: 'blur'
              }
            ]
          },{
            label:'收款项目',
            prop:'itemId',
            row: true,
            type:'select',
            dicUrl:'/api/zt-pay/payItems/itemList?departId={{departId}}&payeeId={{payeeId}}',
            props: {
              label: 'itemName',
              value: 'itemId'
            },
            rules: [{
              required: true,
              message: "请输入项目",
              trigger: "blur"
            }]
          },{
            label: '操作状态',
            prop: 'state',
            type: 'radio',
            row:true,
            display:true,
            dicData:[{
              label:'正常',
              value:1
            },{
              label:'关闭',
              value:0
            }]
          }]
        }
      }
    },
    methods:{
      getInfo(){
        this.Uid = this.$route.query.itemId
        if(this.Uid !== ''){
          let param = {id:this.Uid}
          getList(1,1,param).then(res =>{
            let data = res.data.data.records
            console.log(data)
            this.user.userId = data[0].deptName + '/' + data[0].userName
            this.user.userName = data[0].deptName + '/' + data[0].userName
            this.user.departId = data[0].departId
            this.user.departName = data[0].departName
            this.user.payeeId = data[0].payeeId
            this.user.payeeName = data[0].payeeName
            this.user.itemId = data[0].itemId
            this.user.itemName = data[0].itemName
            this.user.state = data[0].state
            this.user.deptName = data[0].deptName
            this.user.realName = data[0].userName
            this.user.userId2 = data[0].userId
          })
        }
      },
      remoteMethod(query){
        console.log(query)
        getPersonal(query).then((res)=>{
          this.options = res.data.data
        })
      },
      submit() {
        let param = this.user
        param.userIds = this.user2.userIds
        console.log(this.Uid)
        if(this.Uid === ''){//新增
          update(param).then(res => {
            if(res.data.code === 200){
              this.$message({
                type: "success",
                message: "操作成功!"
              });
              this.$router.back();
            }else{
              this.$message({
                type: "error",
                message: res.data.msg
              });
            }
            console.log(res)
          });
        }else{//编辑
          param.id = this.Uid
          if(this.user.userId === this.user.userName){
            this.user.userId = this.user.userId2
          }
          editdate(param).then(res =>{
            if(res.data.code === 200){
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.$router.back();
            }else{
              this.$message({
                type: "error",
                message: res.data.msg
              });
            }
            console.log(res)
          })
        }
      },
      changeUserId(v,id){
        console.log(v)
        console.log(id)
      }
    },
    mounted () {
      this.getInfo()
    },
    watch:{
      'user2.userIds':{
        handler(){

        },
        immediate: true
      }
    }
  }
</script>

<style>
  .avue-form__group{
    padding-top: 20px;
  }
  .select_{
    width: 100%;
  }
</style>
