import request from '@/router/axios';

// 获取表格数据 列表
export const getList = (current, size) => {
  return request({
    url: '/api/zt-pay/statistics/list',
    method: 'get',
    params: {
      current,
      size,
    }
  })
}

// 平台数据 金额统一
export const getMoneyTotal = () => {
  return request({
    url: '/api/zt-pay/statistics/total',
    method: 'get'
  })
}





