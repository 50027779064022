<template>
  <div>
    <el-row>
      <indexs v-if="!isPayKan"></indexs>
      <paykanban v-if="isPayKan"></paykanban>
    </el-row>
  </div>
</template>
<script>
  import paykanban from '@/views/pay/paykanban'
  import indexs from '@/views/order/index'
  import {getMenu} from '@/api/pay/orderForPay'
  export default {
    name: "wel",
    components:{ paykanban,indexs },
    data() {
      return {
        activeNames: ['1', '2', '3', '5'],
        logActiveNames: ['19'],/*  */
        isPayKan: false
      };
    },

    methods: {
      handleChange(val) {
        window.console.log(val);
      },
    },
    mounted(){
      getMenu().then((r)=>{
        if(r.data.data === 'true'){
          this.isPayKan = true
        }else{
          this.isPayKan = false
        }
      })
    }
  };
</script>

<style>
  .el-font-size {
    font-size: 14px;
  }
</style>
