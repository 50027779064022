<template>
  <basic-container>
    <avue-crud :option="option"
               :table-loading="loading"
               :data="data"
               :page.sync="page"
               ref="crud"
               v-model="form"
               @search-change="searchChange"
               @row-save="rowSave"
               @row-update="rowUpdate"
               @on-load="onLoad"
               @search-reset="searchReset">
      <template slot-scope="{type,size,row}" slot="menu">
        <el-button  :type="type" icon="el-icon-view" size="small"
        @click="powerCheck(row)">开通权限</el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>
<style>

</style>
<script>
  import {getList, savePayer, updatePayer} from "@/api/pay/payerAction";
  const defaultQuery = {
    payeeName: '',
    departName: '',
    state: '',
    Id: ''
  }
  export default {
    data(){
      return{
        form:{},
        query:JSON.parse(JSON.stringify(defaultQuery)),
        page: {
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        data:[],
        loading:true,
        option: {
          tip: false,
          simplePage: true,
          searchShow: true,
          searchMenuSpan: 6,
          tree: true,
          delBtn: false,
          border: true,
          index: true,
          selection: true,
          viewBtn: true,
          dialogWidth: 900,
          dialogClickModal: false,
          viewTitle:'详情',
          column: [
            {
              label:'收款方',
              prop:'payeeName',
              dicUrl: "/api/zt-pay/payee/payeeList",
              // dicUrl: "/api/zt-pay/department/list",
              search: true,
              type: "select",
              editDisabled: true,
              editable:false,
              props: {
                label: "payeeName",
                value: "id"
              },
              // dicData: []
            },
            {
              label:'收款部门',
              // display: false,
              prop:'departName',
              search: true,
              rules: [
                {
                  required: true,
                  message: "请输入收款部门",
                  trigger: "blur"
                }
              ]
            },
            {
              label: "状态",
              prop: "state",
              type: "radio",
              dicData: [
                {
                  label: "正常",
                  value: 1
                },
                {
                  label: "关闭",
                  value: 0
                }
              ],
              rules: [
                {
                  required: true,
                  message: "请选择状态",
                  trigger: "blur"
                }
              ]
            },
          ]
        },
      }
    },
    methods: {
      onLoad(page, params = {}) {
        this.loading = true;
        getList(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
          const data = res.data.data;
          console.log(data)
          this.page.total = data.total;
          this.data = JSON.parse(JSON.stringify(data.records));
          this.loading = false;
          //this.selectionClear();
        });
      },
      rowUpdate(row,index,done,loading) {
        console.log(row)
        if(row.payeeName == this.data[row.$index].payeeName){
          row.payeeId = this.data[row.$index].payeeId
        } else {
          row.payeeId = row.payeeName
        }
        updatePayer(row).then(() => {
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          // 数据回调进行刷新
          done(row);
        }, error => {
          window.console.log(error);
          loading();
        });
      },
      rowSave(row, done, loading) {//新增
        row.payeeId = row.payeeName
        console.log(row)
        savePayer(row).then((res) => {
          // 获取新增数据的相关字段
          const data = res.data.data;
          row.id = data.id;
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          // 数据回调进行刷新
          done(row);
          this.onLoad(this.page);
        }, error => {
          window.console.log(error);
          loading();
        });
      },
      searchChange(params, done) {
        console.log(params)
        this.query = params;
        this.page.currentPage = 1;
        this.onLoad(this.page, params);
        done();
      },
      refreshChange() {
        this.$message({
          message: `当前的返回的分页对象${JSON.stringify(this.page)} 搜索表单对象${JSON.stringify(this.search)}`,
          type: 'success',
        });
      },
      searchReset(){
        this.query = JSON.parse(JSON.stringify(defaultQuery))
      },
      // 点击开通权限，页面跳转到权限页面
      powerCheck(row){
        // 传部门id
        console.log(row)
        let query = JSON.stringify({ payeeName:row.payeeName, departName: row.departName,departId: row.id, classId: row.classId })
        sessionStorage.setItem("powerQuery", query);
        if(row.classId !== '' && row.classId !== undefined){
          this.$router.push({ path: '/pay/deptPower'})
        }else{
          this.$router.push({ path: '/pay/addPower'})
        }
      }
    }
  }
</script>
