<template>
  <div>
    <basic-container>
      <!-- <avue-tabs :option="option"
                 v-model="form"
                 @change="handleChange"
                 ></avue-tabs> -->
      
      <!-- <div v-if="type === 'info'">
        <avue-form ref="userForm" v-model="userObj" :option="userOption"
          @submit="handleSubmit"
        ></avue-form>
      </div>
      <div v-else-if="type === 'password'">
        <avue-form ref="passForm" v-model="passObj" :option="passOption"
          @submit="handleSubmit"
        ></avue-form>
      </div> -->


      <el-tabs v-model="activeName" @tab-click="handleChange">
        <el-tab-pane label="个人信息" name="info">
          <avue-form ref="userForm" v-model="userObj" :option="userOption"
          @submit="handleSubmit"></avue-form>
        </el-tab-pane>
        <el-tab-pane label="修改密码" name="password">
          <avue-form ref="passForm" v-model="passObj" :option="passOption"
          @submit="handleSubmit"></avue-form>
        </el-tab-pane>
      </el-tabs>
    </basic-container>
  </div>
</template>

<script>
  import option from "@/const/user/info";
  import {resetRouter} from '@/router/router'
  import {getUserInfo, updateInfo, updatePassword} from "@/api/system/user";
  import md5 from 'js-md5'


  export default {
    data() {
      return {
        type: "info",
        option: option,
        activeName: 'password',
        passObj: {},
        userObj: {},
        userOption: option.column[0].option,
        passOption: option.column[1].option
      };
    },
    created() {
      // alert(this.form)
      // console.log(this.form)
      // this.type = this.$route.query.userType ? this.$route.query.userType : this.option.column[0].prop;
      // console.log(this.type)
      // alert(this.type)
      this.activeName = this.$route.query.userType ? this.$route.query.userType : this.option.column[0].prop;
      this.type = this.activeName
      this.handleWitch();
    },
    methods: {
      handleSubmit(from,done) {
        if (this.type === "info") {
          updateInfo(this.userObj).then(res => {
            if (res.data.success) {
              this.$message({
                type: "success",
                message: "修改信息成功!"
              });
              done()
            } else {
              this.$message({
                type: "error",
                message: res.data.msg
              });
            }
          })
        } else {
          // console.log(this.passObj)
          updatePassword(md5(this.passObj.oldPassword), md5(this.passObj.newPassword), md5(this.passObj.newPassword1)).then(res => {
            if (res.data.success) {
              this.$message({
                type: "success",
                message: "修改密码成功，请您重新登录!"
              });
              this.logout()
              done()
            } else {
              this.$message({
                type: "error",
                message: res.data.msg
              });
            }
          })
        }
      },
      logout() {
            // alert('--')
        // this.$confirm(this.$t("logoutTip"), this.$t("tip"), {
        //   confirmButtonText: this.$t("submitText"),
        //   cancelButtonText: this.$t("cancelText"),
        //   type: "warning"
        // }).then(() => {
        //   this.$store.dispatch("LogOut").then(() => {
        //     resetRouter();
        //     this.$router.push({path: "/login"});
        //   });
        // });
        this.$store.dispatch("LogOut").then(() => {
            resetRouter();
            this.$router.push({path: "/login"});
          });
      },
      handleWitch() {
        // alert(this.type)
        if (this.type === "info") {
          getUserInfo().then(res => {
            const user = res.data.data;
            this.userObj = {
              id: user.id,
              avatar: user.avatar,
              name: user.name,
              realName: user.realName,
              phone: user.phone,
              email: user.email,
            }
          });
        }
      },
      handleChange(item) {
        console.log(item.name)
        // alert(item.name)
        this.type = item.name;
        // console.log(item)
        // console.log(this.type)
        this.handleWitch();
      }
    },
    watch: {
      $route(){
        // this.userType = this.$route.query.userType
        this.activeName = this.$route.query.userType
        this.type = this.activeName
      },
    }
  };
</script>

<style>
</style>
