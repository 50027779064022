import request from '@/router/axios';

export const orderList = (current, size, params) =>{
  return request({
    url: '/api/zt-pay/order/list',
    method: 'get',
    params:{
      ...params,
      current,
      size,
    }
  })
}
export const orderDetail = (id) => {
  return request({
    url: '/api/zt-pay/order/detail',
    method: 'get',
    params:{
      id
    }
  })
}

export const  orderExport = (payMethod,orderNo,itemId,phone,payeeId,departId,status,startTime,endTime,exportType,params) => {
  return request({
    url: '/api/zt-pay/asyncExport/export',
    method: 'get',
    params: {
      ...params,
      payMethod,
      orderNo,
      itemId,
      phone,
      payeeId,
      departId,
      status,
      startTime,
      endTime,
      exportType
    },
    responseType: 'blob'
  })
}

export const getMenu = () =>{
  return request({
    url: '/api/zt-pay/statistics/userMenu',
    method:'get'
  })
}
