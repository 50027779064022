import request from '@/router/axios';

// 获取所有——收款方列表
export const getPayeeList = () => {
  return request ({
    url: '/api/zt-pay/payee/payeeList',
    method: 'get'
  })
}

// 获取对应——收款部门列表
export const getAlldDepartmentList = (payeeId) => {
  return request ({
    url: '/api/zt-pay/department/list',
    method: 'get',
    params: {
      payeeId
    }
  })
}

// 获取对应——支付项目列表
export const getPayItemList = (payeeId, departId, params) => {
  return request({
    url: '/api/zt-pay/payItems/itemList',
    method: 'get',
    params: {
      ...params,
      payeeId,
      departId
    }
  })
}

// 申请——查询数据
export const getDetailInfo = (payMethod,payeeId,departId,itemId,timeStemp,dateTime,startTime,endTime,params) => {
  return request({
    url: '/api/zt-pay/order/recrod',
    method: 'get',
    params: {
      ...params,
      payMethod,
      payeeId,
      departId,
      itemId,
      timeStemp,
      dateTime,
      startTime,
      endTime
    }
  })
}
