<template>
  <div>
    <div style="background-color: white;width: 100%;height: 30px"></div>
    <div class="navigation">
      <div style="display: inline">
        logo
        <img src="" alt="">
      </div>
      <div style="display: inline">
        <a href="#">管理首页</a>
      </div>
      <div style="display: inline">&nbsp;&nbsp;&nbsp;></div>
      <div style="display: inline">
        <a href="#">&nbsp;&nbsp;&nbsp;权限管理</a>
      </div>
    </div>
    <div class="PageContent">
      <div>
        <div class="titleItems">支付看板</div>
        <div class="seg"></div>
        <div style="margin-top: 30px;font-size: 20px">
          <div style="display: inline">支付看板</div>
          <div style="display: inline;margin-left: 200px"><input type="checkbox"></div>
          <div style="display: inline;margin-left: 10px">查看</div>
        </div>
      </div>
      <div>
        <div class="titleItems">用户管理</div>
        <div class="seg"></div>
        <div style="margin-top: 30px;font-size: 20px">
          <div style="display: inline">支付看板</div>
          <div style="display: inline;margin-left: 200px"><input type="checkbox"></div>
          <div style="display: inline;margin-left: 10px">查看</div>
          <div style="display: inline;margin-left: 150px"><input type="checkbox"></div>
          <div style="display: inline;margin-left: 10px">新增</div>
          <div style="display: inline;margin-left: 150px"><input type="checkbox"></div>
          <div style="display: inline;margin-left: 10px">编辑</div>

        </div>
      </div>
      <div>
        <div class="titleItems">支付项目</div>
        <div class="seg"></div>
        <div style="margin-top: 30px;font-size: 20px">
          <div>
            <div style="display: inline">支付项目</div>
            <div style="display: inline;margin-left: 200px"><input type="checkbox"></div>
            <div style="display: inline;margin-left: 10px">查看</div>
            <div style="display: inline;margin-left: 150px"><input type="checkbox"></div>
            <div style="display: inline;margin-left: 10px">新增</div>
            <div style="display: inline;margin-left: 150px"><input type="checkbox"></div>
            <div style="display: inline;margin-left: 10px">编辑</div>
            <div style="display: inline;margin-left: 150px"><input type="checkbox"></div>
            <div style="display: inline;margin-left: 10px">只能看自己服务的</div>
          </div>
          <!---->
          <div>
            <div style="display: inline">&nbsp;&nbsp;&nbsp;&nbsp;收款方</div>
            <div style="display: inline;margin-left: 200px"><input type="checkbox"></div>
            <div style="display: inline;margin-left: 10px">查看</div>
            <div style="display: inline;margin-left: 150px"><input type="checkbox"></div>
            <div style="display: inline;margin-left: 10px">新增</div>
            <div style="display: inline;margin-left: 150px"><input type="checkbox"></div>
            <div style="display: inline;margin-left: 10px">编辑</div>
            <div style="display: inline;margin-left: 150px"><input type="checkbox"></div>
            <div style="display: inline;margin-left: 10px">只能看自己服务的</div>
          </div>
        </div>
      </div>
      <div>
        <div class="titleItems">订单对账</div>
        <div class="seg"></div>
        <div style="margin-top: 30px;font-size: 20px">
          <div>
            <div style="display: inline">支付项目</div>
            <div style="display: inline;margin-left: 200px"><input type="checkbox"></div>
            <div style="display: inline;margin-left: 10px">查看</div>
            <div style="display: inline;margin-left: 150px"><input type="checkbox"></div>
            <div style="display: inline;margin-left: 10px">新增</div>
            <div style="display: inline;margin-left: 150px"><input type="checkbox"></div>
            <div style="display: inline;margin-left: 10px">只能看自己服务的</div>
          </div>
          <!---->
          <div>
            <div style="display: inline">对账服务</div>
            <div style="display: inline;margin-left: 200px"><input type="checkbox"></div>
            <div style="display: inline;margin-left: 10px">查看</div>
            <div style="display: inline;margin-left: 150px"><input type="checkbox"></div>
            <div style="display: inline;margin-left: 10px">新增</div>
            <div style="display: inline;margin-left: 150px"><input type="checkbox"></div>
            <div style="display: inline;margin-left: 10px">只能看自己服务的</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: "limitation"
  }
</script>

<style scoped>
  .head{
    width: 100%;
  }
  .avue-view{
    background-color: white;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .navigation{
    width: 97%;
    margin: 0 auto;
    font-size: 20px;
  }
  .PageContent{
    width: 96%;
    margin: 0 auto;
    /*background-color: #00a680;*/
  }
  .titleItems{
    font-size: 25px;
    font-weight: bold;
    margin-top: 50px;
  }
  .seg{
    width: 90%;
    height: 1px;
    background-color: black;
    margin-top: 20px;
  }

</style>
