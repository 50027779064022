import request from '@/router/axios';

export const GetTree = ()=>{
  return request({
    url: '/api/zt-pay/payee/tree',
    method: 'get',
  })
}
export const onLoad = ()=>{
  return request({
    url:'/api/zt-pay/userPermissions/selectPermissionUsers',
    method: 'post'
  })
}

export const selectPermission = (current,size,payeeId,departId,itemId,chooseType)=>{
  return request({
    url:'/api/zt-pay/userPermissions/selectPermissionUsers',
    method:'post',
    params:{
      current,
      size,
      payeeId,
      departId,
      itemId,
      chooseType
    }
  })
}

export const addPermission = (userId,payeeId,departId,itemId,chooseType)=>{
  return request({
    url: '/api/zt-pay/userPermissions/insertPermissions',
    method: 'post',
    params:{
      userId,
      payeeId,
      departId,
      itemId,
      chooseType
    }
  })
}

export const delPermission = (userId,payeeId,departId,itemId,chooseType)=>{
  return request({
    url: '/api/zt-pay/userPermissions/deletePermissions',
    method: 'post',
    params: {
      userId,
      payeeId,
      departId,
      itemId,
      chooseType
    }
  })
}
