<template>
  <div>
    <basic-container>
      <avue-crud :option="option"
                 :table-loading="loading"
                 :data="data"
                 :page.sync="page"
                 @on-load="onLoad"
                 ref="crud"
                 v-model="form"
                 :before-open="beforeOpen"
                 @refresh-change="refreshChange"
                 @row-save="exportData"
                 @search-change="searchChange"
                 @search-reset="searchReset">
        <template slot="menuLeft">
          <el-button type="primary" size="small" class="exportBtn" icon="el-icon-plus" @click="exportData">新增用户</el-button>
        </template>
        <template slot-scope="scope" slot="menu">
          <el-button type="primary" size="small" class="exportBtn" icon="el-icon-edit" @click="exportEdit(scope.row.id)">编辑</el-button>
        </template>
        <!--<template slot="UserNameSearch">-->
          <!--<avue-select placeholder="请选择用户" v-model="search.userId" :dic="dicUserName"></avue-select>-->
        <!--</template>-->
        <template slot="itemNameSearch">
          <avue-select placeholder="请选择项目" v-model="search.itemName" :dic="dicItemName"></avue-select>
        </template>
        <template slot="payeeIdSearch">
          <avue-select placeholder="请选择收款方" v-model="search.payeeName" :dic="dicPayeeName" @change="payeeChange"></avue-select>
          <!-- <el-select v-model="search.payeeName" value-key="id" filterable placeholder="请选择" @change="payeeChange($event)">
            <el-option
              v-for="item in dicPayeeName"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select> -->
        </template>
        <template slot="departNameSearch">
          <avue-select placeholder="请选择收款部门" v-model="search.departName" :dic="dicDepartName" @change="departChange"></avue-select>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>
<script>
  import { getList } from '@/api/pay/payinperson';
  // import { itemList } from '@/api/pay/payAction'
  import request from '@/router/axios';
  const defaultQuery = {
    payeeName: '',
    itemName: '',
    departName: '',
    state: ''
  }
  export default {
    data() {
      return {
        form: {
          payType: 1,
          money:0,
          state: 1,
          payeeName: ''
        },
        search:{
          itemId:'',
          itemName:'',
          payeeName: '',
          departName: '',
          payeeId:'',
          departId:'',
          userId:'',
          userName:'',
          state:''
        },
        selectState:'',
        dicUserName:[],
        dicItemName:[],
        dicPayeeName:[],
        dicDepartName:[],
        itemId: '',  // 项目id
        itemName: '',  // 项目名:
        isOpen: false,
        dialogVisible: false,
        query: JSON.parse(JSON.stringify(defaultQuery)),
        loading: true,
        data: [],
        page: {
          pageSize: 10,
          currentPage: 1,
          total: 0
        },

        // payeeId:'',  // 收款方 id
        option:{
          page:true,
          align:'center',
          menuAlign:'center',
          dialogWidth:'70%',
          searchShow: true,
          searchSpan:5,
          // searchSpan: 5,
          searchMenuSpan: 5,
          // menuWidth: 300,
          delBtn: false,
          // viewBtnText: '账单查看',
          viewBtn: false,
          addBtn:false,
          editBtn:false,
          addBtnText: '新增用户',
          // editBtnText: '123',
          column:[
            {
              label:'操作用户',
              prop: 'userId',
              type: 'select',
              span:20,
              search: true,
              //searchslot:true,
              props: {
                label: 'userName',
                value: 'id'
              },
              remote:true,
              dicUrl: `/api/blade-user/userPage?phone={{key}}`,
              typeformat(item) {
                console.log('item='+`${item}`)
                return `${item['deptName']}/${item['realName']}`
              },
              rules: [
                {
                  required: true,
                  message: '请选择用户',
                  trigger: 'blur'
                }
              ]
            },
            {
              label: '收款方',
              prop: 'payeeId',
              type: 'select',
              search: true,
              searchslot:true,
              cascaderItem: ['departName'],
              dicUrl: '/api/zt-pay/payee/payeeList',
              editDisabled: false,
              props: {
                label: 'payeeName',
                value: 'payeeId',
              },
              rules: [
                {
                  required: true,
                  message: '请选择收款方',
                  trigger: 'blur'
                }
              ]
            },
            {
              label: '收款部门',
              prop: 'departName',
              type: 'select',
              search: true,
              searchslot:true,
              cascaderIndex:0,
              editDisabled: true,
              dicUrl: '/api/zt-pay/department/list?payeeId={{payeeId}}',
              props: {
                label: 'departName',
                value: 'departId'
              },
              rules: [
                {
                  required: true,
                  message: '请选择收款部门',
                  trigger: 'blur'
                }
              ]
            },
            {
              label:'收款项目',
              prop:'itemName',
              search: true,
              searchslot:true,
              row: true,
              // props: {
              //   label: 'itemName',
              //   value: 'itemId'
              // },
              // searchIndex: 3,
              rules: [{
                required: true,
                message: "请输入项目",
                trigger: "blur"
              }]
            },
            {
              label: '状态',
              prop: 'state',
              value:1,
              type: "radio",
              search: false,
              searchSpan: 4,
              row: true,
              dicData: [
                {
                  label: "正常",
                  value: 1
                },
                {
                  label: "关闭",
                  value: 0
                }
              ],
              change:({value})=>{
                this.selectState = value
              }
            }
          ]
        }
      }
    },
    watch: {

      'form.state':{ // 状态关闭，不可编辑，正常可编辑
        handler(val){
          var itemName =this.findObject(this.option.column,'itemName')
          if(Number(val) === 1){
            itemName.editDisabled = false
          }else{
            itemName.editDisabled = true
          }
        },
        immediate: true
      },
      'search.payeeName':{
        handler(val){
          this.search.departName = ''
          this.search.itemId = ''
          this.dicDepartName = []
        },
        immediate: true
      },
      'search.departName':{
        handler(val){
          this.search.itemName = ''
          this.search.itemId = ''
          this.dicItemName = []
        },
        immediate: true
      }
    },
    methods: {
      // 获取表格列表数据
      onLoad(page, params) {
        this.loading = true;
        getList(page.currentPage, page.pageSize, params).then(res => {
          const data = res.data.data;
          this.page.total = data.total;
          data.records.map((item)=>{
            item.userId = item.deptName + '/'+ item.userName
          })
          this.data = JSON.parse(JSON.stringify(data.records));
          this.loading = false;
        });

      },
      refreshChange(){
        this.onLoad(this.page)
        // this.$message({
        //   message: `当前的返回的分页对象${JSON.stringify(this.page)} 搜索表单对象${JSON.stringify(this.search)}`,
        //   type: 'success',
        // });
      },

      // 比较2个对象, 如果有相同key 则覆盖
      objCover(obj1,ojb2){
        let obj = {}
        for(let key in obj1){
          if (ojb2.hasOwnProperty(key)) {  // 如果有相同key 则覆盖
            obj1[key] = ojb2[key]
          }
        }
        obj = JSON.parse(JSON.stringify(obj1))
        return obj
      },
      // 表单条件查询
      searchChange(params,done) {
        console.log(this.itemName)
        this.search.userId = params.userId
        this.search.payeeId = this.search.payeeName
        this.search.departId = this.search.departName
        this.search.itemId = this.search.itemName
        console.log(this.search)
        let param = {...params, ...this.search}
        this.page.currentPage = 1;
        this.onLoad(this.page, param);
        done();
      },
      // 新增数据
      rowSave(row, done, loading) {
        console.log(row)
        let param = JSON.parse(JSON.stringify(row))
        if (Number(param.payType) === 1){ // 固定金额
          // row.money = 20  // 默认值 20
        }
        param.money = parseInt(Number(param.money) * 100)
        param.itemMoney = parseInt(Number(param.itemMoney) * 100)
        param.departId = param.departName
        add(param).then(() => {
          // console.log(defaultQuery)
          this.onLoad(this.page, defaultQuery);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          done();
        }, error => {
          window.console.log(error);
          loading();
        });
      },
      // 编辑
      rowUpdate(row, index, done, loading) {
        console.log(row)
        let param = {}
        param = JSON.parse(JSON.stringify(row))
        if (Number(param.payType) === 1){ // 固定金额
          // row.money = 20  // 默认值 20
        }
        param.money = parseInt(Number(param.money) * 100)
        param.itemMoney = parseInt(Number(param.itemMoney) * 100)
        if (isNaN(param.departName) === false){ // 这样做是为了解决一个bug(不选择下拉，未修改，departId是对的，修改了departId不对，departName放的是正确的id)
          param.departId = param.departName
        }
        // var itemName =this.findObject(this.option.column,'itemName')
        // if (row.$state === '关闭'){ // 如果状态是关闭 项目不可编辑
        //   itemName.editDisabled = true
        // }else{
        //   itemName.editDisabled = false
        // }

        update(param).then(() => {
          this.onLoad(this.page,defaultQuery);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          done();
        }, error => {
          window.console.log(error);
          loading();
        });
      },
      dialoShare(row){
        alert(row)
        this.dialogVisible = true
        this.itemId = row.id
        this.itemName = row.itemName
        this.isOpen = true
      },
      billCheck(row){
        console.log(row)
        this.$router.push({ path: '/order/orderForPay', query:{itemId:row.id}})
      },
      beforeOpen(done,type){
        // alert(type)
        if(type === 'view'){  // 如果是查看页面
          var money =this.findObject(this.option.column,'money')
          setTimeout(()=>{
            money.display = true
          },0)
          done()
        }

        done()
      },

      // 清空
      searchReset(){
        this.query = JSON.parse(JSON.stringify(defaultQuery))
        let defaultSearch = {
          itemId:'',
          payeeName: '',
          departName: ''
        }
        this.search = JSON.parse(JSON.stringify(defaultSearch))
      },

      // 获取收款方数据
      getPayeeData(){
        request({
          url: '/api/zt-pay/payee/payeeList',
          method: 'get',
        }).then((res)=>{
          let rest = res.data.data
          let arr = []
          rest.forEach(item => {
            arr.push({label: item.payeeName, value:item.payeeId})
          });
          this.dicPayeeName = arr
        })
      },
      // 获取收款部门
      getDepartData(payeeId){
        request({
          url: '/api/zt-pay/department/list',
          method: 'get',
          params: {payeeId:payeeId}
        }).then((res)=>{
          let rest = res.data.data
          let arr = []
          rest.forEach(item => {
            console.log(item)
            arr.push({label: item.departName, value:item.departId})
          });
          this.dicDepartName = arr
        })
      },
      // 获取项目接口数据
      getItemData(){
        // alert('1')
        let params = {payeeId: this.search.payeeName, departId:this.search.departName }
        request({
          url: '/api/zt-pay/payItems/itemList',
          method: 'get',
          params: params
        }).then((res)=>{
          let rest = res.data.data
          let arr = []
          rest.forEach(item => {
            arr.push({label: item.itemName, value:item.itemId})
          });
          this.dicItemName = arr
        })
      },
      // 收款方下拉
      payeeChange(payeeId){
        if(payeeId !== '' && payeeId !== undefined){
          // this.search.departName = ''
          // this.dicDepartName = []
          this.getDepartData(payeeId)
        }
        this.getItemData()
      },
      departChange(departId){
        // alert(this.search.payeeName)

        // if(departId !== '' && departId !== undefined){
        //   let param = {payeeId:this.search.payeeName,departId:departId}
        //   // this.getItemData(param)

        // }
        // alert('1')
        this.getItemData()

      },
      exportData(){//新增
        this.$router.push({ path: '/payInPerson/AddUser', query:{itemId:''}})
      },
      exportEdit(id){//编辑
        this.$router.push({ path: '/payInPerson/AddUser', query:{itemId:id}})
      }
    },
    mounted () {
      // this.$nextTick(()=>{
      this.getPayeeData()
      // let param = {payeeId:this.search.payeeName,departId:departId}
      this.getItemData()
      // })

    }
  }
</script>
<style scoped>
  /deep/ .el-dialog{
    width: 70%;
  }
  /deep/.avue-group .el-collapse-item__header{
    border-bottom: none 0px;
  }
</style>

