<template>
    <div class="deptPowerWarper">
        <div>{{powerQuery.payeeName}} - {{powerQuery.departName}} 收款部门权限组开通</div>
        <div class="powerAdd">
            <avue-input-tree ref="tree" default-expand-all v-model="form" placeholder="请选择内容" type="tree" :dic="dic"></avue-input-tree>
            <el-button  class="addBtn" type="primary" @click="addPower">添 加</el-button>
        </div>
    </div>
</template>
<script>
import request from '@/router/axios';
export default {
    name: 'deptPower',
    data(){
        return {
            form:'',
            dic:[],
            powerQuery:{},
        }
    },
    mounted(){
       this.powerQuery = JSON.parse(sessionStorage.getItem("powerQuery")); 
       this.getDeptPowerList() 
    },
    methods: {
        // 获取下拉权限的所有数据
        getDeptPowerList(){
            request({
                url: '/api/zt-pay/department/getDeptList',
                method: 'get',
                port: '8016',
            }).then((res)=>{
                let resp = res.data.data
                // 把数组对象转成字符串,全局匹配修改key名，然后再把字符串转成json对象
                var arrStr = JSON.stringify(resp).replace(/deptName/g, "label").replace(/id/g, "value");
                this.dic = JSON.parse(arrStr)
            })
        },
        
        // 点击添加按钮
        addPower(){
            // console.log(this.form)
            let departId = ''
            let queryDeptId = this.powerQuery.departId
            if (queryDeptId !== undefined && queryDeptId !== '') {
                departId = queryDeptId
            }
            if(this.form === ''){
                this.$message({
                    type: "error",
                    message: "请选择授权组!"
                });
            }else{
                this.postPower(departId, this.form) // 部门id classId
            }
        },
        // 请求添加/编辑/删除 接口
        postPower(id, classId){
            request({
                url: 'api/zt-pay/department/updateDepartment',
                method: 'post',
                data:{ id: id, classId: classId}
            }).then((res)=>{
                if(res.data.success){
                    this.$message({
                        type: "success",
                        message: "操作成功!"
                    });
                    // 下拉框选择授权组时，classId 已经改变，和默认页面从收款跳转后来的classId 不一样。
                    // 所以要修改本地存储的classId 因为权限列表页面，取的也是sessionStorage
                    this.powerQuery.classId = classId
                    let query = JSON.stringify(this.powerQuery)
                    sessionStorage.setItem("powerQuery", query);
                    this.timer = setTimeout(()=>{
                        this.$router.push({ path: '/pay/deptPower'})
                    },1500)
                }
            })
        },
    },
    destroyed(){
        clearTimeout(this.timer)
    },
    watch:{
    //   form:{
    //      handler() {
    //       setTimeout(()=>{
    //         console.log(this.$refs.tree)
    //         this.form33=this.$refs.tree.getHalfList();
    //         console.log(this.form33)
    //       },0)
    //     },
    //     immediate: true,  
    //   }
    }
}
</script>
<style scoped>
    .deptPowerWarper{ background: #ffffff; padding: 20px;}
    .avue-input-tree{
        width: 60%;
    }
    .powerAdd{
        margin-bottom: 15px;
        margin-top: 15px;
    }
    .powerTable{
        margin-top: 20px;
    }
    .addBtn{ margin-left: 20px; min-width: 60px; padding: 12px 30px;}
    .smallT{ font-size: 14px; color: #666;}
</style>