<template>
  <div>
    <div class="PageContent" ref="printContent">
      <div class="info">
        <!--标题-->
        <div class="TitleBoxWarp">
          <div class="TitleBox">
            <img src="./assets/logo.png">
            <p>收款收据</p>
          </div>
        </div>
        <!--<div class="cutLine"></div>-->
        <!--具体信息-->
        <div class="detailBox">
          <div class="payname">{{d.itemName}}</div>
          <div class="firstInfoBox">
            <div class="infoitem">支付状态：{{d.status === 0 ? '未付款' : d.status === 1 ? '付款中': d.status === 2 ? '付款失败' : '付款成功' }}</div>
            <div class="infoitem">收款方：{{d.payeeName}}/{{d.departName}}</div>
            <div class="infoitem">金额：￥{{(d.money/100).toFixed(2)}}</div>
            <div class="infoitem">支付用户：{{d.payer}}/{{d.phone}}</div>
            <div class="infoitem">备注：{{d.remark}}</div>
          </div>
          <div class="secondInfoBox">
            <div class="infoitem">支付方式：{{d.payMethod}}</div>
            <div class="infoitem">支付单号：{{d.orderNo}}</div>
            <div class="infoitem">交易流水号：{{d.tradeNo}}</div>
            <div class="infoitem">付款时间：{{d.payTime}}</div>
          </div>
        </div>
        <!--<div class="detailBox">-->
          <!--<div class="blackname">{{d.itemName}}标书费</div>-->
          <!--<div class="itemLineBox">-->
            <!--<div class="item">-->
              <!--<div class="lightname">收款方：</div>-->
              <!--<div class="blackname lineheight">{{d.departName}}</div>-->
            <!--</div>-->
            <!--<div class="item">-->
              <!--<div class="lightname">支付状态：</div>-->
              <!--<div class="blackname lineheight">{{d.status === 0 ? '未付款' : d.status === 1 ? '付款中': d.status === 2 ? '付款失败' : '付款成功' }}</div>-->
            <!--</div>-->
          <!--</div>-->
          <!--<div class="itemLineBox">-->
            <!--<div class="item">-->
              <!--<div class="lightname">支付用户：</div>-->
              <!--<div class="blackname lineheight">{{d.payer}}/{{d.phone}}</div>-->
            <!--</div>-->
          <!--</div>-->
          <!--<div class="bottomInfo">-->
            <!--<div class="leftInfo">-->
              <!--<div>备注：{{d.remark}}</div>-->
              <!--<div class="infoheight">支付方式：{{d.payMethod}}</div>-->
              <!--<div class="infoheight">支付单号：{{d.orderNo}}</div>-->
              <!--<div class="infoheight">交易流水号：{{d.tradeNo}}</div>-->
              <!--<div class="infoheight">付款时间：{{d.payTime}}</div>-->
            <!--</div>-->
            <!--<div class="rightInfoBox">-->
              <!--<div class="moneyBox">-->
                <!--<div class="moneytip lightname">金额：</div>-->
                <!--<div class="money">￥{{(d.money/100).toFixed(2)}}</div>-->
              <!--</div>-->
              <!--&lt;!&ndash;<div class="cutLine moneyCutline"></div>&ndash;&gt;-->
            <!--</div>-->
          <!--</div>-->
        </div>
    </div>
    <div class="greyBottomBox">
      <img src="./assets/bg_list.png">
      <div class="printbox" v-if="d.status === 3">
        <el-button type="primary" class="printbtn" @click="printReceipt">打印</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {orderDetail} from '../../api/pay/orderForPay'
  export default {
    name: "orderForDetail",
    data(){
      return {
        d:{}
      }
    },
    methods :{
      getDetail(){
        const id = this.$route.query.id
        
        orderDetail(id).then((r) => {
          console.log(r)
          
          let rest = r.data.data
          if(rest.payMethod === 'AliPay'){
            rest.payMethod = '支付宝'
          }
          if (rest.payMethod === 'MicroMessenger'){
            rest.payMethod = '微信'
          }
          if (rest.payMethod === 'artificial'){
            rest.payMethod = '人工'
          }
          this.d = rest
        })
      },
    //  打印
      printReceipt(){
        let newDomHtml = this.$refs.printContent.innerHTML
        window.document.body.innerHTML = newDomHtml
        window.print()
        window.location.reload()
        return false
      }
    },
    mounted(){
      this.getDetail()
    }
  }
</script>

<style scoped>
  .avue-view{
    background-color: white !important;
    width: 960px;
    margin: auto;
    margin-top: 10px;
    padding: 0;
  }
  .PageContent{
    margin: 0 auto;
    box-shadow: rgba(0,34,66,0.06) 0 0 50px;
    /*background-color: #00a680;*/
  }
  .info{
    padding: 30px 65px 90px 65px;
  }
  .TitleBoxWarp{
    height: 43px;
    padding-bottom: 25px;
    border-bottom: 1px solid #666666;
  }
  .TitleBox{
    height: 100%;
    position: relative;
  }
  .TitleBox img{
    width: 120px;
    display: inline-block;
  }
  .TitleBox p{
    display: inline-block;
    font-size: 21px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
  .detailBox{
    margin-top: 40px;
  }
  .payname{
    font-size: 15px;
  }
  .firstInfoBox{
    margin-top: 40px;
    padding-bottom: 30px;
    border-bottom: 1px dashed #999999;
  }
  .infoitem{
    font-size: 12px;
    margin-top: 15px;
  }
  .secondInfoBox{
    margin-top: 30px;
  }
  .greyBottomBox{
    width: 100%;
    /*height: 100px;*/
    background: rgb(240,242,245);
    margin: 0;
    padding: 0;
    position: relative;
  }
  .greyBottomBox img{
    width: 100%;
    position: absolute;
    top: 0;
  }
  .printbox{
    width: 100%;
    height: 80px;
    display: flex;
    vertical-align: middle;
    position: absolute;
    top: 45px;

  }
  .printbtn{
    width: 280px;
    height: 80px;
    font-size: 21px;
    background: #409eff;
    margin: auto;
    box-shadow: rgba(64,158,255,0.35) 0 0 50px;
  }
  .cutLine{
    width: 100%;
    height: 1px;
    background: #e7e7e7;
    margin-top: 25px;
  }
  .blackname{
    font-size: 14px;
  }
  .lightname{
    font-size: 12px;
    color: #999999;
  }
  .lineheight{
    margin-top: 15px;
  }
  .itemLineBox{
    margin-top: 30px;
  }
  .item{
    display: inline-block;
    width: 50%;
  }
  .bottomInfo{
    margin-top: 60px;
    position: relative;
    font-size: 12px;
  }
  .bottomInfo:after{
    content: '';
    display: block;
    clear: both;
    overflow: hidden;
    height: 0;
  }
  .leftInfo{
    width: 50%;
    float: left;
  }
  .rightInfoBox{
    width: 50%;
    float: right;
    padding-bottom: 30px;
    /*border-bottom: 1px solid #e7e7e7;*/
    border-bottom: 1px solid black;
  }
  .moneytip{
    float: left;
  }
  .money{
    font-size: 21px;
    float: right;
  }
  .moneyCutline{
    margin-top: 30px;
  }
  .moneyBox:after{
    content: '';
    display: block;
    clear: both;
    overflow: hidden;
    height: 0;
  }
  .infoheight{
    margin-top: 20px;
  }
</style>
