<template>
  <div class="transferWarper">
    <div class="title">人工转账</div>
    <avue-form ref="form" v-model="formObj" :option="option">
      <template slot="menuForm">
        <el-button icon="el-icon-user" type="primary" @click="handleSubmit">提 交</el-button>
        <el-button icon="el-icon-delete" @click="handleEmpty">清 空</el-button>
      </template>
    </avue-form>
  </div>
</template>

<script>
import request from '@/router/axios';
  export default {
    name: "transferAccount",
    data() {
      return {
        formObj:{},
        option:{
          emptyBtn:false,
          submitBtn:false,
          column: [
            {
                label: '收款方',
                prop: 'payeeName',
                type: 'select',
                row:true,
                // dataType:'number',
                // value: this.$route.query.payeeId,
                props: {
                  label: 'payeeName',
                  value: 'payeeId'
                },
                dicUrl: '/api/zt-pay/payee/payeeList',
                rules: [
                  {
                    required: true,
                    message: '请选择收款方',
                    trigger: 'blur'
                  }
                ]
            },
            {
              label: "收款方式",
              prop: "paymentType",
              span: 6,
              type: "radio",
              row:true,
              value: 1,
              dicData: [
                {
                  label: '转入',
                  value: 1
                },
                {
                  label: '转出',
                  value: 2
                },
              ],
              mock:{
                type:'dic'
              },
              change:({value,column})=>{
                  // this.$message.success('change')
              }
            },
            {
              label: '收款金额',
              prop:'money',
              row:true,
              rules: [
                {
                  required: true,
                  message: '请输入收款金额',
                  trigger: 'blur'
                }
              ]
            },
            {
              label: '备注',
              prop: 'remark',
              type:'textarea',
              showWordLimit:true,
            }
          ]
        }
      }
    },
    methods:{
      // 提交 请求后端
      addPostData(obj){
        let query = JSON.parse(JSON.stringify(obj))
        let money = parseInt(Number(query.money) * 100)
        query.money = money
        if(query.paymentType === 2){ // 转出
          query.money = -money
        }
        request({
          url: '/api/zt-pay/order/orderSave',
          method: 'post',
          data: query
        }).then((res)=>{
          if(res.data.success){
            this.$message.success('操作成功')
            // alert('--')
            this.timeper = setTimeout(() => {
              this.$router.push({path:'/capitalPool/paymentPool'})
            }, 1000);
          }
        })
      },
      handleEmpty(){
        this.$refs.form.resetForm();
      },
      handleSubmit(form,done){
        this.$refs.form.validate((vaild,done)=>{
          if(vaild){
            // this.$message.success(JSON.stringify(this.formObj));
            setTimeout(()=>{
              this.addPostData(this.formObj)
              done()
            },2000)
          }
        })
      }
    }
  }
</script>
<style scoped>
.transferWarper{
  background: #fff !important;
  padding: 20px;
}
.transferWarper .title{
  padding: 15px;
  font-size: 18px;
}
</style>
