<template>
  <div>
    <div style="background-color: white;width: 100%;height: 30px"></div>
    <div class="navigation">
      <div style="display: inline">
        logo
        <img src="" alt="">
      </div>
      <div style="display: inline">
        <a href="#">管理首页</a>
      </div>
      <div style="display: inline">&nbsp;&nbsp;&nbsp;></div>
      <div style="display: inline">
        <a href="#">&nbsp;&nbsp;&nbsp;支付项目</a>
      </div>
      <div style="display: inline">&nbsp;&nbsp;&nbsp;></div>
      <div style="display: inline">
        <a href="#">&nbsp;&nbsp;&nbsp;编辑项目信息</a>
      </div>
    </div>
    <div class="PageContent">
      <div class="PageTitle">收款方信息：</div>
      <!--左侧标题-->
      <div class="LeftTitle" style="width: 8%;float: left;margin-top: 30px">
        <div class="Left_Label">收款方：</div>
        <div class="Left_Label">状态：</div>
        <div class="Left_Label"></div>
      </div>
      <!--右侧内容-->
      <div class="RightContent" style="width: 55%;float: left;margin-top: 30px;">
        <div class="Right_Content"><input type="text" placeholder="中天钢铁集团" style="padding-left: 5px;height: 40px;width: 90%;background-color: #f2f2f2"></div>
        <div class="Right_Content">
          <el-radio v-model="radio" label="1">正常</el-radio>
          <el-radio v-model="radio" label="2">关闭</el-radio>
        </div>
        <div class="Right_Content" @click="ps">
          <button class="ConfirmBtn" @click="ps">确认</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import {payshou} from '@/api/pay/pay'
  export default {
    name: "payAddPayee",
    data(){
      return{
        radio: ''
      }
    },
    methods:{
      ps: function () {
        payshou('1','1').then((r) => {
          console.log(r)
        })
      }
    }
  }
</script>

<style scoped>
  .head{
    width: 100%;
  }
  .avue-view{
    background-color: white;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .navigation{
    width: 97%;
    margin: 0 auto;
    font-size: 20px;
  }
  .PageContent{
    width: 92%;
    margin: 4% auto;
  }
  .PageTitle{
    font-size: 25px;
  }
  .Left_Label{
    font-size: 20px;
    color: #b3b3c1;
    height: 80px;
    line-height: 80px;
  }
  .Right_Content{
    font-size: 18px;
    height: 80px;
    line-height: 80px;
  }
  .Radio{
    zoom: 160%;
  }
  .ConfirmBtn{
    background-color: white;
    width: 200px;
    height: 50px;
    letter-spacing: 5px;
  }

</style>
