import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/zt-pay/department/pageList',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const savePayer = (row) => {
  return request({
    url: '/api/zt-pay/department/saveDepartment',
    method: 'post',
    data: row
  })
}

export const updatePayer = (row) => {
  return request({
    url: '/api/zt-pay/department/updateDepartment',
    method: 'post',
    data: row
  })
}

// export const getDepart = () => {
//   return request({
//     url: '/api/zt-pay/department/list',
//     method: 'get',
//   })
// }

// export const getPayee = () => {
//   return request({
//     url: '/api/zt-pay/payee/payeeList',
//     method: 'get',
//   })
// }

