import request from '@/router/axios';

// 获取表格数据 列表

export const getList = (current, size, params) => {
  return request({
    url: '/api/zt-pay/outgoingCollection/list',
    method: 'post',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const update = (row) => {
  return request({
    url: '/api/zt-pay/outgoingCollection/insert',
    method: 'post',
    data: row
  })
}

export const editdate = (row) => {
  return request({
    url:'/api/zt-pay/outgoingCollection/update',
    method:'post',
    data:row
  })
}

export const getPersonal = (key) => {
  return request({
    url:'/api/blade-user/userPage?phone='+key,
    method:'get',
  })
}
