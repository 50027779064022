<template>
  <div>
    <div class="navigation">
      <div>
        <div style="font-size: 30px;font-weight: bold">ZTPAY在线支付</div>
        <div style="font-weight: bold">中天钢铁集团统一收款服务平台</div>
      </div>
    </div>
    <div class="Page">
      <div class="payState">
        <div style="display: inline">支付状态：</div>
        <div style="display: inline">已付款</div>
      </div>
      <div class="seg"></div>
      <div class="tenderFee">
        <div class="tenderFee_title">
          <div style="display: inline">xx</div>
          <div style="display: inline;">项目标书费</div>
        </div>
        <div class="tenderFee_content">
          <div class="contentItem">
            <div style="display: inline">收款方：</div>
            <div style="display: inline">中天钢铁/人力资源部</div>
          </div>
          <div class="contentItem">
            <div style="display: inline">金额：</div>
            <div style="display: inline">￥50.00</div>
          </div>
          <div class="contentItem">
            <div style="display: inline">支付用户：</div>
            <div style="display: inline">许四山/1564563456</div>
          </div>
          <div class="contentItem">
            <div style="display: inline">备注：</div>
            <div style="display: inline">华山建筑设计有限公司</div>
          </div>
        </div>
      </div>
      <div class="seg"></div>
      <div class="payInformation">
        <div class="contentItem">
          <div style="display: inline">支付方式：</div>
          <div style="display: inline">在线支付</div>
        </div>
        <div class="contentItem">
          <div style="display: inline">支付单号：</div>
          <div style="display: inline">18819934567</div>
        </div>
        <div class="contentItem">
          <div style="display: inline">交易流水号：</div>
          <div style="display: inline">2018122022001476450507509007 </div>
        </div>
        <div class="contentItem">
          <div style="display: inline">付款时间：</div>
          <div style="display: inline">2020-10-15 10:09:22</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "paySuccess"
  }
</script>

<style scoped>
  .head{
    width: 100%;
  }
  .avue-view{
    background-color: white;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .navigation{
    background-color: #D7D7D7;
    padding:20px 20px;
  }
  .Page{
    padding: 30px 20px;
  }
  .payState{
    font-size: 25px;
    color: #70B603;
  }
  .seg{
    width: 100%;
    height: 1px;
    border-top: 1px dashed #b3b3c1;
    margin-top: 30px;
  }
  .tenderFee{
    margin-top: 30px;
  }
  .tenderFee_title{
    font-weight: bold;
    font-size: 25px;
  }
  .tenderFee_content{
    font-size: 20px;
  }
  .contentItem{
    margin-top: 20px;
    font-size: 20px;
  }
</style>
