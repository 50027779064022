<template>
<div>
  <div class="navigation">
    <div>
      <div style="font-size: 30px;font-weight: bold">ZTPAY在线支付</div>
      <div style="font-weight: bold">中天钢铁集团统一收款服务平台</div>
    </div>
  </div>
  <div class="Page">
    <div class="Page_title">
      <div style="display: inline">{{d.itemName}}</div>
    </div>
    <div class="Page_content">
      <div>
        <div style="display: inline">收款方：</div>
        <div style="display: inline">{{d.payeeName}}</div>
      </div>
      <div style="margin-top: 30px">
        <div style="display: inline">金额：</div>
        <div style="display: inline;font-weight: bold">{{d.money}}</div>
      </div>
      <div style="margin-top: 30px">支付方：</div>
      <div style="margin-top: 30px">
        <div style="display: inline">姓名：</div>
        <div style="display: inline"><input type="text" class="Page_input" placeholder="请输入姓名" v-model="d.payer"></div>
      </div>
      <div style="margin-top: 20px">
        <div style="display: inline">手机：</div>
        <div style="display: inline"><input type="text" class="Page_input" placeholder="请输入手机号" v-model="d.phone"></div>
      </div>
      <div style="margin-top: 20px">
        <div style="display: inline">备注：</div>
        <div style="display: inline"><input type="text" class="Page_input" placeholder="请输入备注信息" v-model="d.remark"></div>
      </div>
      <div style="margin-top: 30px">支付方式：</div>
      <div style="margin-top: 20px">
        <div style="display: inline"><input type="radio" style="zoom: 130%"></div>
        <div style="display: inline;margin-left: 10px">微信</div>
      </div>
      <div style="margin-top: 20px">
        <div style="display: inline"><input type="radio" style="zoom: 130%"></div>
        <div style="display: inline;margin-left: 10px">支付宝</div>
      </div>
      <div style="margin-top: 30px">
        <button class="payBtn" @click="encryption">去支付</button>
      </div>
    </div>
  </div>
</div>
</template>
<script>
  import {alipay} from "../../api/pay/alipay";

  export default {
    name: "PaymentPage",
    data(){
      return{
        key:'',
        appId: '',
        ts: '',//时间戳
        nonce: '',
        d: {
          money: '',
          itemName: '',
          payeeName: '',
          payer: '',
          phone: '',
          remark: '',
          payMethod:'',
          departName: ''
        }
      }
    },
    methods: {
      encryption (){
        this.ts = new Date().getTime()
        this.d.ts = this.ts
        console.log(this.ts)
        this.d.key = this.key
        this.nonce = this.createCode(6)
        this.d.nonce = this.nonce
        let a = AEC.encrypt(JSON.stringify(this.d),this.key)
        console.log(a)
        alipay(this.appId,this.nonce,this.ts,a).then((r) => {
          console.log(r)
        })
      },
      createCode(length) {
        let code = '';
        let codeLength = length;
        let random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9);
        for (let i = 0; i < codeLength; i++) {
          let index = Math.floor(Math.random() * 9);
          code += random[index];
        }
        return code
      }
    }
  }
</script>

<style scoped>
  .head{
    width: 100%;
  }
  .avue-view{
    background-color: white;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .navigation{
    background-color: #D7D7D7;
    padding:20px 20px;
  }
  .Page{
    padding: 30px 20px;
  }
  .Page_title{
    font-size: 25px;
  }
  .Page_content{
    font-size: 20px;
    margin-top: 30px;
  }
  .Page_input{
    width: 50%;
    padding-left: 10px;
    height: 40px;
  }
  .payBtn{
    background-color: white;
    width: 180px;
    height: 45px;
  }
</style>
