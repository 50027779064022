<template>
    <div>
        <!-- 支付资金池 -->
        <basic-container>
            <div class="balance">
                <span>资金池余额：¥{{balance}}</span>
                <el-button type="primary" size="small" @click="gotoPage()">查看资金流向</el-button>
            </div>
            <div>
                <avue-crud ref="crud" :data="data" :option="option" :page.sync="page" 
                @on-load="onLoad">
                    <template slot-scope="{type,size,row}" slot="menu">
                        <el-button  icon="el-icon-view" :size="size" :type="type" @click="gotoPayee(row)">查看</el-button>
                        <el-button icon="el-icon-check" :size="size" :type="type" @click="gotoTransfer(row)">人工转账</el-button>
                    </template>
                </avue-crud>
            </div>
        </basic-container>
    </div>
</template>
<script>
import request from '@/router/axios';
export default {
    data() {
      return {
        loading: true,
        page: {
          pageSize: 10,
          pagerCount:5
        },
        data: [],
        balance: '',
        option: {
          align: 'center',
          menuAlign: 'center',
          addBtn: false,
          editBtn: false,
          delBtn: false,
          column: [
            {
              label: '收款方',
              prop: 'payeeName'
            },
            {
              label: '账户余额',
              prop: 'balance'
            }
          ]
        }
      }
    },
    mounted(){
        this.getPoolTotal()
    },
    methods: {
      onLoad(page, params) {
        this.loading = true;
        let query = { current:page.currentPage, size:page.pageSize, ...params }
        request({
          url: '/api/zt-pay/capitalPool/payPoolList',
          method: 'get',
          params: query
        }).then((res)=>{
          const data = res.data.data;
          this.page.total = data.total;
          data.records.map((item)=>{
            item.balance = parseFloat(Number(item.balance) / 100).toFixed(2)
          })
          this.data = JSON.parse(JSON.stringify(data.records));
          this.loading = false;
        })
      },
      getPoolTotal(){
        request({
          url: '/api/zt-pay/capitalPool/balance',
          method: 'get',
        }).then((res)=>{
            let rest = res.data.data
            this.balance =  parseFloat(Number(rest.balance) / 100).toFixed(2)
        })
      },
      // 查看资金流向
      gotoPage(){
        this.$router.push({path: '/capitalPool/PoolBill'})
      },
      // 跳转到收款方页面
      gotoPayee(row){
        console.log(row)
        this.$router.push({path: '/capitalPool/payeeBill',query: {payeeId: row.payeeId, payeeName:row.payeeName}})
      },
      // 跳转到人工转账页面
      gotoTransfer(row){
          this.$router.push({path: '/capitalPool/transferAccount',query: {payeeId: row.payeeId, payeeName:row.payeeName}})
      }
    }
}
</script>
<style scoped>
    .balance span{ margin-right: 15px;}
    /* .el-button{ margin-left: 15px;} */
</style>