import request from '@/router/axios';

// 获取表格数据 列表

export const getList = (params) => {
  return request({
    url: '/api/zt-pay/access/detail',
    method: 'get',
    params: params
  })
}
export const update = (row) => {
  return request({
    url: '/api/zt-pay/access/save',
    method: 'post',
    data: row
  })
}






