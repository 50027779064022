<template>
  <div class="PageContent">
    <div class="Page">
      <!--对账服务-->
      <el-row>
        <el-col>
          <p style="color: #717171;font-weight: bold;font-size: 25px">对账服务</p>
        </el-col>
      </el-row>
      <div class="PageItem">
        <!--收款方-->
        <el-row class="RowCss">
          <el-col :span="2">
            <label class="Pcss">收款方：</label>
          </el-col>
          <el-col :span="5">
            <el-select v-model="payeeId" clearable placeholder="全部收款方" class="borderCSS" @change="selectPayeeId(payeeId)">
              <el-option v-for="item in payeeList" :key="item.payeeId" :label="item.payeeName" :value="item.payeeId"></el-option>
            </el-select>
          </el-col>
          <el-col :span="9">
            <el-select v-model="departId" clearable  placeholder="全部收款部门" class="borderCSS"  @change="selectDepartment(departId)">
              <el-option v-for="item in departmentList" :key="item.departId" :label="item.departName" :value="item.departId"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <!--支付项目-->
        <el-row class="RowCss">
          <el-col :span="2">
            <label class="Pcss">支付项目：</label>
          </el-col>
          <el-col :span="18">
            <el-select v-model="id" clearable placeholder="全部项目" class="borderCSS" @change="selectPayItem(id)">
              <el-option v-for="item in itemList" :key="item.id" :label="item.itemName" :value="item.id"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <!--支付方式-->
        <el-row class="RowCss">
          <el-col :span="2">
            <label class="Pcss">支付方式：</label>
          </el-col>
          <el-col :span="18">
            <el-select v-model="payMethod" clearable placeholder="请选择支付方式" class="borderCSS" @change="selectPayMethod(payMethod)">
              <el-option v-for="item in options" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <!--对账时间-->
        <el-row class="RowCss">
          <el-col :span="2">
            <label class="Pcss">对账时间：</label>
          </el-col>
          <el-col :span="20">
            <el-radio-group v-model="timeStemp" @change="changeType()">
              <el-radio  label="year" style="zoom: 120%" >年度</el-radio>
              <el-radio  label="month" style="zoom: 120%">月度</el-radio>
              <el-radio  label="date" style="zoom: 120%">时间区间</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
        <!--年份选择-->
        <el-row class="RowCss">
          <el-col :span="2">
            <label class="Pcss">请选择：</label>
          </el-col>
          <!--选择年-->
          <el-col :span="18" v-if="showYear">
            <div class="block">
              <el-date-picker v-model="selectYear"  value-format="yyyy" type="year" placeholder="请选择年份" @change="SelectYearTime(selectYear)"></el-date-picker>
            </div>
          </el-col>
          <!--选择月-->
          <el-col :span="18" v-show="showMonth">
            <div class="block" style="float: left">
              <el-date-picker v-model="selectYear" type="year" value-format="yyyy" placeholder="请选择年份" @change="SelectYearTime(selectYear)"></el-date-picker>
            </div>
            <div class="block" style="float: left;margin-left: 30px">
              <el-date-picker v-model="selectMonth" format="MM" value-format="MM" type="month" placeholder="请选择月份" @change="SelectMonthTime(selectMonth)"></el-date-picker>
            </div>
          </el-col>
          <!--选择区间-->
          <el-col :span="18" v-show="showSection">
            <div class="block">
              <el-date-picker v-model="selectSection"
                      type="daterange"
                      align="right"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      @change="SelectSectionTime(selectSection)"
                      value-format="yyyyMMdd">
              </el-date-picker>
            </div>
          </el-col>
        </el-row>
        <!--按钮-->
        <el-row class="RowCss">
          <el-col :span="2"></el-col>
          <el-col :span="18">
            <el-button class="SearchBtn" type="primary" @click="SearchDetail()">查询信息</el-button>
          </el-col>
        </el-row>
        <!--查询数据-->
        <el-row class="RowCss">
          <el-col :span="2">
            <label class="Pcss">查询数据:</label>
          </el-col>
          <el-col :span="18" v-show="searchInfo">
            <div>{{detailList.time}}</div>
            <el-divider></el-divider>
            <div>在线支付笔数：{{detailList.order}}&nbsp;笔</div>
            <div style="margin-top: 10px">在线支付金额：{{detailList.money}}&nbsp;元</div>
            <button class="detailCss" @click="findDetailBtn()">查看详细订单</button>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
  import {getPayeeList, getAlldDepartmentList, getPayItemList, getDetailInfo} from "@/api/pay/orderForCheck";
  export default {
    name: "orderForCheck",
    data () {
      return {
        payeeName: '', // 收款方姓名
        departName: '', // 收款部门
        itemsName: '', // 支付项目
        showYear: true,
        showMonth: false,
        showSection: false,
        showStartTime:'', //补全时间
        showEndTime:'',
        // 收款方列表
        payeeList: {},
        // 收款部门列表
        departmentList: {},
        // 支付项目列表
        itemList: {},
        // 被选中收款方id
        payeeId: '',
        // 被选中的收款部门id
        departId: '',
        // 被选中的支付项目id
        id: '',
        // 被选中的支付方式
        payMethod:'',
        // 时间选择——年
        selectYear: '',
        // 时间选择——月
        selectMonth: '',
        // 时间选择——区间
        selectSection: '',
        // 区间选择快捷键
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        //  radio获取——时间选择类型
        timeStemp: '',
        // 当timeStemp为year或month时，时间参数dateTime
        dateTime: '',
        // 当timeStemp为data时，时间参数为startTime和endTime
        startTime: '',
        endTime: '',
        // 显示数据列表
        detailList: {
          // 支付金额
          money: '',
          // 支付订单数
          order: '',
          // 显示时间
          time: ''
        },
        // 查询数据
        searchInfo: false ,
      //  支付方式
        options:[{
          value:'AliPay',
          label:'支付宝'
        },{
          value:'MicroMessenger',
          label:'微信'
        },{
          value:'artificial',
          label:'人工支付'
        }]
      }
    },
    methods: {
      //当前时间
      currentTime(){
        setInterval(this.formatDate, 500)
      },
      // formatDate() {
      //   this.nowTime = new Date()
      // },
      // 根据radio选中值，切换不同的时间选择样式
      changeType () {
        let type = this.timeStemp
        if (type === 'year') {
          this.showYear = true
          this.showMonth = false
          this.showSection = false
        } else if (type === 'month') {
          this.showYear = false
          this.showMonth = true
          this.showSection = false
        } else if (type === 'date') {
          this.showYear = false
          this.showMonth = false
          this.showSection = true
        }
        this.selectYear = ''
        this.selectMonth = ''
        this.selectSection = ''
      },
      // 发起——申请支付项目列表
      getPayItem(){
        getPayItemList(this.payeeId, this.departId).then(res => {
          if (res.status !== 200) {
            return this.$message.error('获取支付项目列表失败！')
          }
          this.itemList = res.data.data
        })
      },
    //  发起——申请收款方列表
      getAllPayee () {
        getPayeeList().then(res => {
          if (res.status !== 200) {
            return this.$message.error('获取收款方列表失败！')
          }
          this.payeeList = res.data.data
        })
      },
      // 监听——被选中的收款方
      selectPayeeId (payeeId) {
        // alert('--')
        this.payeeId = payeeId
        // 更改收款方选项，置空收款部门、支付申请
        this.departId = ''
        this.id = ''
        // 发起——申请收款部门列表
        getAlldDepartmentList(this.payeeId).then(res => {
          if (res.status !== 200) {
            return this.$message.error('获取收款部门列表失败！')
          }
          this.departmentList = res.data.data
        })
        this.getPayItem()
      },
      // 监听——被选中的收款部门
      selectDepartment (departId) {
        this.departId = departId
        this.id = ''
        this.getPayItem()
      },
      // 监听——被选中的支付项目
      selectPayItem (id) {
        this.id = id
        console.log(this.id)
      },
      //
      selectPayMethod(payMethod){
        this.payMethod = payMethod
        console.log(this.payMethod)
      },
      //  监听——年份选择
      SelectYearTime (selectYear) {
        this.selectYear = selectYear
      },
      //  监听——月份选择
      SelectMonthTime (selectMonth) {
        this.selectMonth = selectMonth
      },
      //  监听——时间区间选择
      SelectSectionTime (selectSection) {
        this.selectSection = selectSection
      },
      // 发起——数据查询请求
      SearchDetail () {
        this.dateTime = this.selectYear + this.selectMonth
        this.startTime = this.selectSection[0]
        this.endTime = this.selectSection[1]
        if(this.timeStemp === 'year'){
          if(this.selectYear === '' || this.selectYear === null){
            return this.$message.warning('请选择年份！')
          }
          this.showStartTime = this.selectYear + '-01-01 00:00:00'
          this.showEndTime = this.selectYear + '-12-31 23:59:59'
        } else if(this.timeStemp === 'month') {
          if(this.selectYear === '' || this.selectYear === null){
            return this.$message.warning('请选择年份！')
          } else if(this.selectMonth === ''||this.selectMonth === null) {
            return this.$message.warning('请选择月份！')
          }
          this.showStartTime = this.selectYear + '-' + this.selectMonth + '-01 00:00:00'
          this.showEndTime = this.selectYear + '-' + this.selectMonth + '-' + new Date(this.selectYear, this.selectMonth,0).getDate() + ' 23:59:59'
        } else if(this.timeStemp === 'date') {
          if(this.selectSection === ''|| this.selectSection === null) {
            return this.$message.warning('请选择时间区间！')
          }
          this.showStartTime = this.startTime.substring(0,4)+'-'+this.startTime.substring(4,6)+'-'+this.startTime.substring(6,8) + ' 00:00:00'
          this.showEndTime = this.endTime.substring(0,4)+'-'+this.endTime.substring(4,6)+'-'+this.endTime.substring(6,8) + ' 23:59:59'
        }
        console.log(this.id)
        // 发起——查询信息请求
        getDetailInfo(this.payMethod,this.payeeId, this.departId, this.id, this.timeStemp, this.dateTime, this.startTime, this.endTime).then(res => {
          console.log(res)
          if (res.status !== 200) {
            return this.$message.error('查询订单信息失败！')
          }
          this.detailList.money = res.data.data.money/100
          this.detailList.order = res.data.data.orders
          if (this.timeStemp === 'year') {
            this.detailList.time = res.data.data.dateTime + '年'
          } else if (this.timeStemp === 'month') {
            this.detailList.time = res.data.data.dateTime.substring(0,4) + '年' + res.data.data.dateTime.substring(4,6) + '月'
          } else  if (this.timeStemp === 'date') {
            this.detailList.time = this.startTime.substring(0,4)+'年'+this.startTime.substring(4,6)+'月'+this.startTime.substring(6,8)+'日至'
                +this.endTime.substring(0,4)+'年'+this.endTime.substring(4,6)+'月'+this.endTime.substring(6,8)+'日'
          }
        })
        this.searchInfo = true
      },
      // 查看详情
      findDetailBtn () {
        //this.$router.push({path: '/order/orderForPay',params:{payMethod:this.payMethod,payeeId:this.payeeId,departId:this.departId,id:this.id,startTime:this.showStartTime,endTime:this.showEndTime,status:3}})
        this.$router.push({name: '支付订单',params:{payMethod:this.payMethod,payeeId:this.payeeId,departId:this.departId,id:this.id,startTime:this.showStartTime,endTime:this.showEndTime,status:3}})
      }
    },
    mounted() {
      this.getPayItem()
      this.changeType()
      this.getAllPayee()
      this.currentTime()

    }
  }
</script>

<style scoped>
  .head{
    width: 100%;
  }
  .PageContent{
    background-color: white;
  }
  .Page{
    padding: 30px 30px;
    font-size: 20px;
    min-width: 1366px;
    background-color: #ffffff
  }
  .PageItem{
    margin-left: 30px;
    background-color: #ffffff
  }
  .Pcss{
    color: #9696a2;
  }
  .borderCSS{
    align-items: center;
  }
  .RowCss{
    margin-top: 30px;
  }
  .detailCss{
    margin-top: 30px;
    border: none;
    outline: none;
    color: #1e9fff;
    font-size: 20px;
    background: none;
    padding: 0;
  }
  .SearchBtn{
    width: 200px;
    height: 50px;
    font-size: 20px;
  }
</style>
